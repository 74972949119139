@import "src/themes/default/default";

.excel-header-structure-loader {
  &__steps {
    padding-bottom: 30px;
  }

  .step-content {
    &__description {
      padding-bottom: 20px;
      color: @black;
      font-size: 16px;
    }

    &__actions {
      padding-bottom: 30px;
    }
  }
}

.parsed-file-preview-table {
  padding-top: 15px;

  .ant-table {
    border-color: @gray-600;
    border-radius: 0;
    border-bottom: 0;
  }

  .ant-table-cell {
    padding: 8px 5px;
    white-space: nowrap;
  }

  .ant-table-tbody {
    tr {
      td {
        border-bottom-color: @gray-600;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";