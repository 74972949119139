@import "../../../../shared/styles/mixins";

.inline-fields-delete {
  margin-bottom: 0 !important;

  .form-fields {
    align-items: flex-start;
  }

  .delete-action-btn {
    border-radius: 5px;
    padding: 6px;
    width: 36px;

    svg {
      margin-left: unset;
    }

    margin-top: 30px;

  }

  .add-action-btn {
    width: unset !important;

    svg {
      margin-top: 1px;
      fill: @link-complement-light;
    }

    font-weight: 500;
    padding: 0;
    display: flex;
    gap: 8px;

    &:hover {
      color: @blue-600;

      svg {
        fill: @blue-600;
      }
    }

  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";