@import 'src/themes/default/default';
@import '../../shared/styles/mixins';

.layout {
  background-color: @layout-color;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  &-side-menu {
    height: 100vh;
    position: sticky;
    top: 0;
    background: #3D608F;
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  &-wrapper {
    display: flex;
    min-height: 100vh;
  }

  &__content {
    flex-grow: 2;
    padding: 16px 16px 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    &.darken {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 90;
      height: 100%;
      background-color: rgba(20, 20, 20, 0.3);
    }

    >div {
      border-radius: 10px;
    }
  }

  &__spinner {
    flex-grow: 1;
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .ant-layout-sider {
    display: contents;
    overflow: visible;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";