@import 'src/themes/default/default';

.dd-filter-expression {
  &-operand {
    &__group {
      display: flex;
      flex-grow: 1;
      width: 100%;
      min-height: 146px;
      overflow: hidden;

      &::before {
        content: '';
        margin-left: -1px;
        width: 30px;
        flex-shrink: 0;
        height: 1px;
        background-color: rgba(#cce2ff, .8);
        margin-bottom: 16px;
        align-self: flex-end;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      overflow: hidden;
      // gap: 8px;

      &__body {
        border: 1px solid rgba(#cce2ff, .8);
        background-color: rgba(#e6f1ff, .1);
        padding: 12px;
        width: 100%;

        &__title {
          margin-bottom: 4px;
        }

        &__form {
          border-radius: 3px;
          display: flex;
          align-items: center;
          gap: 8px;
          flex-wrap: wrap;
        }
      }

      &__switch {
        display: flex;
        align-items: center;
        background-color: @base-light;
        border: 1px solid @blue-100;
        color: @complement-light;
        border-radius: 3px;
        padding: 0 12px;
        min-height: 36px;
        gap: 12px;
        justify-content: center;

        &_disabled {
          color: @complement-light;
          background-color: @background-faint-light;
          opacity: 0.5;
        }

        .ant-form-item {
          flex-grow: 0 !important;
        }
      }

      .ant-form-item {
        flex-shrink: 0;
        flex-grow: 1;
        // flex-basis: 10% !important;
        margin-bottom: 0;
      }

      &::before {
        content: '';
        margin-left: -1px;
        width: 30px;
        flex-shrink: 0;
        height: 1px;
        background-color: rgba(#cce2ff, .8);
        margin-bottom: 16px;
        align-self: flex-end;
      }
    }
  }

  &-group {
    flex-grow: 1;
    border: 1px solid rgba(#cce2ff, .8);
    padding: 16px;
    border-radius: 5px;
    // background-color: rgba(#e6f1ff, .1);

    &_main {
      min-height: 152px;
    }

    &__upbar {
      border-radius: 4px;
      display: flex;
      justify-content: space-between;

      &_sticky {
        position: sticky;
        top: 50px;
        z-index: 10;
      }
    }

    &__operands {
      padding-left: 20px;
      padding-top: 20px;
      display: flex;
      overflow: hidden;

      &__border {
        width: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(#cce2ff, .8);
        margin-top: -20px;
        margin-bottom: 16px;
        // margin-left: 20px;
      }

      &__body {
        .ant-form {
          width: 100%;
        }

        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
        align-items: baseline;
        overflow: hidden;

        &__no-rules {
          margin-left: -18px;
          position: absolute;
          color: @faint-dark;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";