.tooltip-list {

  .ant-card-bordered {
    border: 0;
  }

  .ant-card-body {
    padding: 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";