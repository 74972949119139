@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

.default-header {
  &__breadcrumbs {
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__caption {
    flex-grow: 1;
  }

  &__info {
    display: flex;
    gap: 32px;
  }

  .header-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__name,
    &__value {
      font-size: 14px;
      line-height: 20px;
    }

    &__name {
      color: @gray-600;
      margin-bottom: 12px;
      white-space: nowrap;
    }

    &__value {
      text-align: left;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";