@import 'src/shared/styles/mixins.less';

.page-jumper {
  display: flex;

  &__input {
    border-radius: 3px 0 0 3px;
  }

  &__jump-button {
    .set-flex(@direction: row, @justify: center);

    border-radius: 0 3px 3px 0;

    &-icon {
      font-size: 12px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";