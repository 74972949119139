@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.scroll-container {
  max-height: 430px;
  overflow: auto;
  .scroll();
  padding-right: 7px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  border: 1px solid @line-faint-light;
  border-radius: 5px;

  .checked-all-indicator {
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid @line-faint-light;
    min-height: 44px;

    overflow: hidden;

    :first-child {
      border-radius: 5px 5px 0 0 !important;
    }
  }

  .ant-checkbox-wrapper {
    &:last-child {
      border-radius: 0 0 5px 5px;
      border-bottom: 0;
    }

    min-height: 44px;
    margin: 0;
    padding: 0 10px;

    background: @gray-100;
    color: @text-gray-400;

    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid @line-faint-light;

    .ant-checkbox-inner {
      width: 14px;
      height: 14px;
    }

    &.ant-checkbox-wrapper-checked {
      background: white;
      color: @text-gray;
    }

    &.ant-checkbox-wrapper-disabled {
      background: @gray-100;
      color: @text-gray-400;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";