@import "../../shared/styles/mixins";

.confirm-modal {

  .modal-close-icon {
    position: absolute;
    left: 12px;
    bottom: 8px;
  }

  .ant-space {
    width: 100%;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    width: 100%;
  }

  .ant-btn {
    width: 100%;
  }
}

.error-list{
  height: 300px;
  overflow-y: scroll;
  scrollbar-color: #B7C1D4 #F3F5F8;
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";