@import 'src/themes/default/default';

.drill-down-rule-info {
  display: flex;
  margin-bottom: 24px;

  &__middle {
    // flex-grow: 1;
    margin: 10px 12px 0 12px;

    &__link {
      display: flex;
      justify-content: center;
      margin-top: 6px;
      padding: 0 50px;
      align-items: center;
      gap: 8px;

      &__popover {
        color: @faint-dark;

        &__body {
          max-width: 450px;
        }
      }

      &__title {
        font-size: 12px;
      }
    }

    &__arrow {
      margin: 0px 16px;
      height: 1px;
      background-color: #E0E4F0;
      display: flex;

      &::after {
        content: '';
        width: 5px;
        height: 5px;
        display: block;
        margin-top: -4px;
        margin-left: calc(100% - 5px);
        @w: 5px;
        width: 0;
        height: 0;
        border-top: @w solid transparent;
        border-bottom: @w solid transparent;
        border-left: @w solid #E0E4F0;
      }


      &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 9999px;
        background-color: #E0E4F0;
        display: block;
        margin-top: -2px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";