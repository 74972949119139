@import "src/themes/default/default";

.login-page {
  &__alert-error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-alert {
      border-radius: 5px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";