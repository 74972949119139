@import "src/themes/default/default";

.custom-link-meta {
  .custom-link-button {
    &__disabled {
      color: @critic-light;
      opacity: .5;
      cursor: not-allowed;
    }

    .ant-skeleton-element{
      width: 100%;
      .ant-skeleton-button {
        width: 100%;
      }
    }
  }

  &.submenu-item {
    .ant-skeleton-button {
      max-height: calc(32px - 10px); // max-height - top and bottom paddings
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";