@import "../../../../shared/styles/mixins";

.news-widget__title {
  margin-bottom: 0 !important;
}

.news-widget__chart {
  margin: 0 auto;
}

.news-widget__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.news-widget {

  .ant-card-body {
    padding: 16px;
  }

  border-radius: 10px;
  max-height: 540px;
  overflow-y: auto;
  .scroll();

  .ant-list-split .ant-list-item {
    border-bottom: 0;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";