@import "src/themes/default/default";

.file-list-uploader {
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  .file-field-info {
    padding: 8px 5px 8px 7px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";