@import "src/themes/default/default";

.widget-page {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  min-height: 954px;

  .widget-block {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    gap: 16px 0;

    flex-grow: 1;
    min-width: 550px;
    transition: background-color .3s linear;

    &.dragging {
      border: 1px dashed @gray-400;
      background-color: @line-faint-light;
    }

    .widget-component {
      transition: unset;
      max-height: 519px;
      height: 100%;

      &.auto-adjust-height {
        max-height: unset;

        .ant-card {
          height: inherit;
          min-height: unset;
          max-height: unset;
        }
      }

      cursor: url("../../shared/assets/cursor/openhand.svg"),
      grab;
    }
  }

  .ant-spin-nested-loading {
    width: 100%;
  }

}

body.dragging {
  cursor: url("../../shared/assets/cursor/closedhand.svg"), grabbing;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";