@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

.collapsible-header {
  margin-bottom: 24px;

  &.collapsible {
    .ant-collapse-header {
      padding-left: 28px !important;
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 24px;
    color: @text-accent-dark !important;
    cursor: pointer;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: @line-faint-light;
      margin: auto 0 auto 16px;
    }
  }

  .ant-collapse-no-arrow {
    .ant-collapse-header {
      cursor: auto;
      color: @black;
    }
  }

  .ant-collapse-item {
    .ant-collapse-arrow {
      color: @accent-dark !important;
      transition: all 250ms ease-in-out;
      transition: hover 0s;
      left: 0 !important;

      &:hover {
        color: @complement-light !important;
      }
    }

    &-active {
      .ant-collapse-arrow {
        transform: rotate(180deg);
        transition: all 250ms ease-in-out;
        transition: hover 0s;
      }
    }
  }

  .ant-collapse-content {
    &-box {
      padding: 24px 0 0 0 !important;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";