@import "src/themes/default/default";

.open-settings-page-button {
  display: flex;
  justify-content: center;

  &__settings-icon {
    cursor: pointer;
    color: @gray-icon;

    &:hover {
      color: @link-complement-light;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";