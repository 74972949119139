@import "src/themes/default/default";

.open-report-configuration-history-modal {
  display: flex;
  justify-content: center;

  &__icon {
    cursor: pointer;
    fill: @gray-icon;

    &:hover {
      fill: @link-complement-light;
    }
  }
}

.history-modal {
  &__line {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & span {
      &:nth-child(1) {
        color: @faint-dark;
      }

      &:nth-child(2) {
        margin-left: 8px;
        color: black;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";