.transport-container-card {
  .spinner-container {
    justify-content: flex-start;
    margin-left: 10px;

    .ant-spin {
      display: flex;

      &-dot {
        font-size: 18px;
      }

      &-text {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";