.additional-fields {
  &-form {
    &__segments {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-end;

      & .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";