.overflown-component {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;

    &__content {
        white-space: normal;
        max-width: 40vw;
        word-break: break-word
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";