.table-report-side-filter {
  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .ant-btn {
      flex-grow: 1;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";