@import "src/themes/default/default";
@import "src/shared/styles/mixins";

.login-form {
  .auth-form();
  z-index: 2;

  &__buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }

  &__error {
    .auth-error();
  }

  &__fields {
    .auth-fields();

    &__info {
      color: @info;
      margin-bottom: 5px;
      margin-top: -5px;
    }
  }

  &__link {
    display: block;
    margin-top: @fields-vertical-gap;
    text-decoration: underline;
    color: @white !important;
    font-size: 16px;
  }

  &__link:visited {
    color: @white !important;
  }

  &__animation {
    -webkit-animation-name: fade_in;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out;
  }

  &__animation-off {
    -webkit-animation-name: fade_out;
    -webkit-animation-duration: 0.3s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-out;
  }

  @keyframes fade_in {
    from {
      transform: translateY(-50%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade_out {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(-50%);
      opacity: 0;
      color: @gray-400;
    }
  }
}

.login-form-tooltip {
  .auth-tooltip();
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";