@import 'src/themes/default/default';

.dropdown {
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;

  &.ant-dropdown-open {
    background: white;
    color: @link-complement-light;

    svg {
      fill: @link-complement-light;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";