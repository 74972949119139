@import "src/themes/default/default";

.block-user-button {
  width: 100%;

  &.danger {
    border-width: 1.5px;
    color: @red-600;
    border-color: @red-600;
    background-color: @white;

    &:active,
    &:hover,
    &:focus-visible {
      color: @white;
      border-width: 0;
      background-color: @red-600;

      .anticon svg {
        fill: @white;
      }
    }

    .anticon svg {
      fill: @red-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";