@import "src/themes/default/default";

.tc-status-list-item {
  display: flex;
  gap: 0 4px;

  .status {
    &.red {
      fill: @red-600;
    }

    &.green {
      fill: @green-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";