.loader {
  height: 42px;

  .ant-skeleton-element,
  .ant-skeleton-button {
    width: 100%;
    height: 36px;
  }

}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";