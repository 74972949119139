.directory-item {
  &__controls {
    padding-bottom: 24px;

    &__button {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  .ant-descriptions-item-label {
    width: 300px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";