@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.request-form {
  .ant-card-head {
    min-height: 48px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(20, 20, 20, 0.85);
    font-weight: 500;
    font-size: 24px;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
  }

  .ant-modal-body {
    padding-bottom: 30px;
  }

  .ant-card-body {
    padding: 0;
    margin: 16px 30px 0;
  }

  .message-replies-card {
    .ant-card-body {
      border-radius: 3px;
      border: 1px solid @line-faint-light;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";