@import 'src/themes/default/default';
@import '../../../../shared/styles/mixins';
@import 'src/shared/styles/ant-theme-override';

.text-modal-popover {
  .ant-popover-content {
    border-radius: 5px;

    .ant-popover-inner-content {
      padding: 16px !important;
      min-width: 399px;
    }

    .ant-form-item-explain {
      display: initial !important;
    }

    .form-tooltip {
      .ant-form-item {
        margin-bottom: 16px;
      }

      .buttons {
        margin-top: 16px;

        .ant-space-item {
          margin-right: 0 !important;

          .form-tooltip-button {
            min-width: 175.5px;
          }
        }
      }
    }
  }
}


.text-modal-cell {

  svg {
    fill: @default-gray;
    color: @default-gray;
    min-width: 16px;
    min-height: 16px;

    &:hover {
      fill: @primary-color
    }
  }

  & .active {
    fill: @primary-color;

    svg {
      fill: @primary-color;
    }
  }

  & .required {
    fill: @red-600;

    svg {
      fill: @red-600;
    }
  }

  .ant-tooltip-arrow-content {
    width: 10px;
    height: 10px;
  }
}

.text-tooltip {
  display: flex;
  gap: 4px;
  align-items: center;

  &__content {
    border-right: 1px solid @default-gray;
    padding-right: 4px;
    word-wrap: break-word;
    max-width: 320px;
  }

  svg {
    fill: @default-gray;
    color: @default-gray;
    min-width: 16px;
    min-height: 16px;

    &:hover {
      fill: @link-complement-light
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";