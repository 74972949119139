.ais-request-statistics-dashboard {
    &__legend {
        margin-top: 32px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        gap: 26px;

        &__mark {
            height: 10px;
            width: 10px;
        }

        &__body {
            display: flex;
            gap: 12px;
            font-size: 14px;
        }
    }


    &__chart {
        &__body {
            min-height: 194px;
            display: flex;
            justify-content: center;
            gap: 20px;
            margin: 50px 0;
        }
    }   

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";