@import "src/themes/default/default";

.directories-company-form {
  &-card>.ant-card-head {
    .ant-card-head-title {
      padding: 0;
    }
  }

  &-tabs-wrapper {
    .ant-tabs-content {
      padding: 0;
      border: none;
      padding-top: 20px;
      border-top: 1px solid @accent-dark;
      border-radius: 0 !important;
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        transition: none;

        &:after {
          background: @accent-dark;
        }

        .ant-tabs-tab-btn {
          transition: none;
          font-size: 16px;
          border: none;
        }

        &-active {
          border: none;
          border-radius: 0 !important;
          border-bottom: 2px solid @accent-dark !important;

          .ant-tabs-tab-btn {
            color: @accent-dark;
            font-weight: bold;
          }
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";