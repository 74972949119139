.tabbed-form {
  .ant-card-body {
    padding-bottom: 0;
    min-height: 100px;
  }

  .ant-modal-footer {
    border: 0;
    padding: 16px 30px 30px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";