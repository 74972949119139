@import '../../../../../src/themes/default/default';

.ant-form-item-has-error {
  .color-picker {
    &__preview {
      border-color: #ED1C24 !important;
    }
  }
}

.color-picker {
  &__preview {
    border: 1px solid #E0E4F0;
    display: inline-flex;
    height: 36px;
    border-radius: 3px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    color: @gray-600;
    font-size: 18px;
    cursor: pointer;

    &:hover,
    &.ant-popover-open {
      border: 1px solid @blue-600;
    }

    &.ant-popover-open {
      .color-picker__preview__arrow svg {
        transform: rotate(180deg);
      }
    }

    &__main {
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-items: center;

      &_empty {
        padding: 0px 12px;
        justify-content: unset;
        align-items: unset;
        flex-grow: 0;
      }
    }

    &__arrow {
      margin-right: 8px;

      svg {
        transition: transform linear .1s;
      }
    }

    &__empty {
      font-size: 14px;
      font-weight: 400 !important;
      color: @faint-dark;
      line-height: 1.1;
      white-space: nowrap;
    }

    &__fill {
      border-radius: 3px;
      width: 32px;
      height: 20px;
    }

    &__underline {
      height: 2px;
      width: 18px;
      margin-top: -5px;
      margin-bottom: 3px;
    }
  }

  &__popover {
    &__presets {
      margin-top: 4px;
      width: 100%;
      display: flex;
      gap: 4px;
      justify-content: space-between;

      div {
        border-radius: 4px;
        width: 20px;
        height: 10px;
        cursor: pointer;
      }
    }

    .ant-input {
      width: 120px;
      margin-top: 6px;
      height: 20px;

      @f-size: 10px;
      font-size: @f-size;

      &::placeholder {
        font-size: @f-size;
      }
    }

    .react-colorful {
      width: 120px;
      height: 120px;

      &__hue {
        margin-top: 3px;
        height: 12px;
        border-radius: 4px;
      }

      &__saturation {
        border-radius: 4px;
        border: 0px;
      }

      &__pointer {
        @size: 12px;
        height: @size;
        width: @size;
      }
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";