@import "src/themes/default/default";

.download-document-list-item {
  display: flex;
  gap: 4px;

  svg {
    fill: @link-complement-light;
    cursor: pointer;
    margin-top: -2px;

    &:hover {
      fill: @blue-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";