@import 'src/themes/default/default';

.ais-dashboard-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  flex-grow: 1;
  min-width: 300px;

  &__title {
    color: @faint-dark;
    font-size: 18px;
    font-weight: 500;
  }

  &__body {
    margin-top: 16px;
  }

  &__footer {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";