@import 'src/themes/default/default';

.aside-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 444px;
  font-size: 12px;
  height: 36px;
  cursor: pointer;
  color: @faint-dark;

  &:hover {
    background-color: @gray-100;
  }

  &.active {
    background-color: @faint-light;
    color: @white;

    .aside-row__icon {
      fill: @white;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &__icon {
    fill: @black;
    width: 8px;
    padding: 14px;
    box-sizing: content-box;

    &.open {
      transform: rotate(180deg);
    }

    &.inactive {
      fill: @inactive-gray;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";