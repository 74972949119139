@import "src/themes/default/default";

.monitor-service-fields {
  width: 100%;
  position: relative;
  margin: 10px 0;

  &__title {
    position: absolute;
    top: -10px;
    left: 16px;
    color: @default-gray;
    background-color: @white;
    font-weight: 600;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    border: 1px solid @line-faint-light;
    border-radius: 5px;
    padding: 20px 16px 24px 16px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";