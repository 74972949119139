.additional-information {
  display: flex;
  gap: 4px;

  .comparison-icon {
    margin-top: 5px;
    fill: #B7C1D4;

    path {
      fill: #B7C1D4;
    }
  }

  .download-log-icon {
    cursor: pointer;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";