@import "../../../../shared/styles/mixins";

.button-save-editable {
  .button-primary();
  .button-no-hover_focus();
  margin: 16px 0;
  width: 100%;

  &-spin {
    .ant-spin-text {
      padding-left: 8px;
      color: @black !important;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";