@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

.table-column-filter {
  &-controls {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    margin: 10px 10px 10px 0;

    &__update-apply-popover {
      padding: 5px 15px;

      &__buttons {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }

  &-loader {
    padding: 8px;

    .spinner-container {
      padding: 5px 0;
    }

    &-popover {
      width: 450px;

      &-content {
        display: flex;
        flex-direction: column;

        &__buttons {
          display: flex;
          justify-content: flex-end;
          column-gap: 8px;
        }

        .ant-row {
          margin-bottom: 20px;
        }
      }

      &__icon {
        &.active {
          fill: #FABC42;
        }
      }

      &__delete-icon {
        padding-right: 4px;

        svg {
          color: @warning;
        }
      }

      .ant-popover-inner {
        padding: 15px 18px;
      }

      .ant-popover-title {
        font-weight: normal;
        padding: 0;
      }

      &.d-mode .ant-popover-title {
        padding-bottom: 12px;
        border-bottom: 0;
      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }

    &-form {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }
}

.table-column-filter-expression {
  &-creator {
    .scroll();
    min-width: 585px;
    max-width: 80vw;
    max-height: 30vh;
    overflow-y: auto;
    text-transform: none !important;
  }

  &-operand {
    &__form {
      width: 100% !important;
    }

    &__group {
      display: flex;
      flex-grow: 1;
      width: 100%;
      min-height: 146px;
      position: relative;

      &::before {
        content: '';
        margin-left: -1px;
        width: 30px;
        flex-shrink: 0;
        height: 1px;
        background-color: rgba(#cce2ff, .8);
        margin-bottom: 16px;
        align-self: flex-end;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      min-width: 450px;

      &__body {
        border: 1px solid rgba(#cce2ff, .8);
        background-color: rgba(#fafcfe, .8);
        padding: 12px;
        width: 100%;

        &__title {
          margin-bottom: 4px;
        }

        &__form {
          border-radius: 3px;
          display: flex;
          align-items: center;
          gap: 8px;
          flex-wrap: nowrap;

          .ant-form-item-label {
            margin-bottom: -6px;
          }
        }
      }

      &__switch {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #E0E4F0;
        border-radius: 3px;
        padding: 0 12px;
        min-height: 36px;
        gap: 12px;
        justify-content: center;

        &_disabled {
          background-color: #F8FAFD;
        }

        .ant-form-item {
          flex-grow: 0 !important;
        }
      }

      .ant-form-item {
        flex-shrink: 0;
        flex-grow: 1;
        margin-bottom: 0;
      }

      &::before {
        content: '';
        margin-left: -1px;
        width: 30px;
        flex-shrink: 0;
        height: 1px;
        background-color: rgba(#cce2ff, .8);
        margin-bottom: 16px;
        align-self: flex-end;
      }
    }
  }

  &-group {
    flex-grow: 1;
    border: 1px solid rgba(#cce2ff, .8);
    padding: 16px;
    border-radius: 5px;

    &_main {
      min-height: 152px;
      border: unset;
      padding: 8px;
    }

    &__upbar {
      display: flex;
      justify-content: space-between;
    }

    &__operands {
      padding-left: 20px;
      padding-top: 20px;
      display: flex;

      &__border {
        width: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(#cce2ff, .8);
        margin-top: -20px;
        margin-bottom: 16px;
      }

      &__body {
        .ant-form {
          width: 100%;
        }

        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
        align-items: baseline;

        &__no-rules {
          margin-left: -18px;
          position: absolute;
          color: @faint-dark;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";