.sorting-settings-modal-opener,
.grouping-settings-modal-opener {
  &__form {
    .form-list {
      &__header {
        display: flex;
        align-items: center;
        gap: 16px
      }

      &__items {
        .ant-checkbox-wrapper {
          margin-top: 16px;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";