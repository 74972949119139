@import 'src/themes/default/default';
@import '../../shared/styles/mixins';

.report-configuration {
  display: flex;
  flex-direction: column;

  &__card {
    border-radius: 10px;

    &>.ant-card-head {
      .card_head();
    }

    &>.ant-card-body {
      padding-top: 32px;
    }
  }


  .svg-icon-size-16 {
    &>svg {
      height: 16px;
      width: 16px;
    }
  }

  .button-only-icon {
    &.ant-btn-icon-only {
      padding: 0 !important;
      min-width: 36px;

      &>svg {
        margin-left: 0 !important;
      }
    }
  }

  .form-list {
    .scroll();
    overflow: auto;
    padding-bottom: 16px;
    margin-top: 16px;

    max-height: 524px;

    &__items {
      display: flex;
      column-gap: 16px;
      align-items: flex-start;

      .ant-input-number {
        width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &>.ant-row.ant-form-item {
        flex-shrink: 0;
        margin-bottom: 0 !important;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";