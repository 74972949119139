@import "src/themes/default/default";
@import "../../../../../../../shared/styles/mixins";

.table-report-filter-form {
  &.hidden {
    display: none;
  }

  .header-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;

    .ant-form-item {
      flex-grow: 1;
      margin-bottom: 0;
      height: 100%;

      .ant-form-item-control-input-content>div {
        width: 100% !important;
      }

      &.without-label {
        .ant-form-item-control {
          height: 100%;
          justify-content: flex-end;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";