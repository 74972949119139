@import 'src/themes/default/default';

.external-systems-settings {
  &__link {
    color: @accent-dark;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";