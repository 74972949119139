@import '../../report-configuration.mixins.less';
@import '../../../../shared/styles/mixins.less';


.report-dd-filters {

    .ant-input-number-input-wrap {
        margin-top: 2px;
    }

    .ant-select-selection-placeholder {
        margin-top: 3px !important;
    }

    &__dd-rule {
        &__label {
            display: flex;
            font-weight: 500;
            gap: 7px;
            svg {
                height: 18px;
                width: 18px;
            }
        }
        display: flex;
        gap: 16px;
        // max-width: 800px;
    }

    &__buttons-bar {
        .buttons-bar-mixin();
        margin-bottom: 26px;
        flex-grow: 1;
    }

    &__section-title {
        .section-title-mixin();
        &::after {
            margin-left: 0;
        }
    }

    &__body {
        .ant-table-content {
            .scroll();
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";