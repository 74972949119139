@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

.render-formats-icon(@icon) {
  &::after {
    content: url("../../../shared/assets/@{icon}.svg");
  }

  &:hover,
  &.ql-active {
    &::after {
      filter: brightness(0) invert(1);
    }
  }
}

.editor-toolbar {
  &.hidden {
    visibility: hidden;
    height: 0;
    padding: 0;
  }

  &.ql-toolbar {
    border: 1px solid @line-faint-light;
    border-top: 0;
    border-radius: 0 0 3px 3px;
  }

  .ql-formats {
    display: flex;
    gap: 0 16px;

    button[ant-click-animating-without-extra-node='true'] {
      &:after {
        opacity: 1;
        animation: none;
        position: inherit;
      }
    }

    button,
    .ql-picker-label {
      .button-default();
      .button-default-hover();
      outline: none;
      height: 28px;

      svg {
        display: none;
      }

      &.ql-active {
        color: @white;
        background: @default-gray;
        border: 1.5px solid @default-gray;
        opacity: 1;
      }

      &::after {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: @default-gray;
      }

      &:hover,
      &.ql-active {
        &::after {
          color: @white;
        }
      }
    }

    .ql-bold {
      &::after {
        content: "Ж";
      }
    }

    .ql-italic {
      &::after {
        content: "K";
        font-style: italic;
        font-weight: 400;
      }
    }

    .ql-underline {
      &::after {
        content: "Ч";
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .ql-strike {
      &::after {
        content: "A";
        font-weight: 500;
        text-decoration: line-through;
      }
    }

    .ql-list {
      &::after {
        margin-top: 6px;
      }
    }

    .ql-list[value="bullet"] {
      .render-formats-icon("/rich-text/bullet-list");
    }

    .ql-list[value="ordered"] {
      .render-formats-icon("/rich-text/ordered-list");
    }

    .ql-color {
      width: 47px;

      .ql-picker-label {
        width: 47px;
        outline: none;

        &::before {
          content: url("../../../shared/assets/rich-text/color-changer.svg");
          position: absolute;
          left: 8px;
          top: 3px;
        }

        &:after {
          content: url("../../../shared/assets/caret-down.svg");
          position: absolute;
          right: 8px;
          top: 0;
        }

        &:hover,
        &.ql-active {
          &::before {
            content: url("../../../shared/assets/rich-text/color-changer-hover.svg");
          }

          &:after {
            filter: brightness(0) invert(1);
          }
        }
      }

      .ql-picker-options {
        flex-wrap: wrap;
        gap: 6px 21px;
        padding: 12px;


        border: 1px solid @line-faint-light;
        margin-top: 7px;
        width: 270px;

        .ql-picker-item {
          outline: none;
          width: 32px;
          height: 32px;
          margin: 0;

          background-clip: content-box;
          padding: 1px;
        }
      }

      &.ql-expanded {
        .ql-picker-options {
          display: flex;
        }
      }
    }

    span.ql-expanded.ql-color-picker~div .ant-popover {
      display: none;
    }

    .ql-link {
      .render-formats-icon("link");

      &::after {
        margin-top: 5px;
      }

      &_disabled {
        opacity: 0.5;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";