@import "src/themes/default/default";

.directories-company-form-header {
  display: flex;
  margin-top: 32px;
  padding-bottom: 12px;

  &-title {
    font-size: 24px;
    font-weight: 400;
    padding: 0;
    line-height: 28px;
    color: @text-accent-dark;
    margin-right: 26px;
  }

  &-buttons {
    display: flex;
    gap: 12px
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";