@import "../../../../shared/styles/mixins";
@import "src/themes/default/default";

.number-square {
  pointer-events: none;

  &.primary {
    .button-primary();
  }

  &.default {
    .button-default();
    background-color: @line-faint-light;
    color: @text-gray;
    border-color: @line-faint-light;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";