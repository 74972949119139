@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.tabs-with-references {
  min-height: 100vh;

  .sider-menu {
    min-height: 100%;
    border: 1px solid @line-faint-light;
    background: @white;
    border-radius: 5px;

    .divider {
      border-left: 2px solid @blue-600;
    }

    .trigger {
      height: inherit;
      border-left: 1px solid @line-faint-light;
      background-color: @gray-100;
      width: 12px;
      cursor: pointer;
      padding-left: 10px;

      &_icon {
        font-size: 14px;
        color: @icon-blue-200;
        position: absolute;
        opacity: .5;
        top: 5px;
        right: -2px;
      }
    }

    .ant-layout-sider-children {
      height: 100%;

      .ant-menu {
        font-size: 16px;
        color: @faint-dark;
        border: none;
        transition: none;

        &-item {
          display: flex;
          background: none;

          &:hover {
            color: @base-dark;
          }

          &-selected {
            font-weight: 500;

            &:hover {
              color: @text-complement-light;
            }
          }
        }
      }

      .ant-menu-inline-collapsed {
        .ant-menu-item {
          overflow: hidden;
          padding-left: 24px;
        }
      }

      .ant-menu-inline {
        border: none;
      }
    }

    .ant-menu-item-only-child {
      a {
        color: @faint-dark !important;

        &:hover {
          color: @base-dark !important;
        }
      }
    }

    .ant-menu-item-selected {
      a {
        color: @text-complement-light !important;
        font-weight: 500;

        &:hover {
          color: @text-complement-light !important;
        }
      }

      &:after {
        content: '';
        background-color: @text-complement-light;
        position: inherit;
        margin: auto 12px;
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        transform: matrix(1, 0, 0, -1, 0, 0);
        animation: select-animate .2s linear;
      }
    }
  }

  .ant-card-head {
    .card_head();
  }

  .ant-card-body {
    max-height: 100%;
    height: 90%;

    .ant-card-bordered {
      border: none;
    }
  }

  .ant-layout.ant-layout-has-sider>.ant-layout-content {
    margin-right: 20px;
    border-left: 1px solid @line-faint-light;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @link-complement-light;
  }

  .ant-card {
    .ant-card-body {
      padding: 24px 16px 24px 20px;
    }

    .ant-card-head {
      min-width: 83.375rem;
    }
  }

  .field-block {
    .ant-card-body {
      padding: 0;

      .ant-form-item {
        flex-grow: 1;
      }
    }
  }

  .content {
    .ant-card-head {
      margin-top: 24px;
    }

    .ant-card-head-title {
      font-size: 20px;
    }


    .administration-settings-control-ratios-object-item,
    .administration-settings-control-ratios-main,
    .administration-settings-control-ratio-assignments {
      column-gap: 16px !important;
      row-gap: 10px;

      .table-action:not(:first-child) {
        margin-left: 0;
      }
    }

    .table-container__actions {
      margin: 0;
      border: 0;
      background: @white;
      padding: 0 0 24px;

      .subsections {
        margin: 0;
      }
    }

    .ant-table {
      .scroll();
      overflow-x: auto;

      border: 0;

      table {
        border-radius: 5px;
        border: 1px solid @line-faint-light;
      }
    }
  }

  .form {
    .ant-form-item {
      height: unset;
    }
  }

  .form-page {
    .ant-card-head-wrapper {
      .custom-card-title {
        .title {
          color: @text-accent-dark;
          font-weight: 400;
          font-size: 20px;
          margin: 0;
          padding: 0;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .confirm-modal {
    .ant-card-head {
      margin: 0 30px;
      padding: 0;
      border-color: #E0E4F0;
    }

    .ant-card-head-title {
      font-weight: 600;
      font-size: 16px;
      padding: 30px 0 16px;

      &:after {
        content: none;
      }
    }

    .ant-modal-close-x {
      position: relative;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-card-body {
      padding: 16px 30px 30px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";