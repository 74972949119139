@import 'src/themes/default/default';

.footer {
  z-index: 2;

  &__button-text {
    cursor: pointer;
  }

  &.fixed {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 0;

  min-height: 40px;
  font-size: .875rem;
  line-height: 2;
  color: @white;

  &:before {
    content: "";
    position: absolute;
    width: 80%;
    left: 10%;
    top: 0;
    height: 1px;
    border-bottom: 1px solid #959DAC;
  }

  &.dark {
    color: @text-gray;

    &:before {
      content: none;
    }
  }

  .footer__link {
    text-decoration: underline;
    color: @footer-text-color;
  }

  &__button {
    cursor: pointer;
    padding: 4px;
    margin-right: 4px;
    color: @footer-icon-color;
  }

  &__delimiter {
    font-size: 20px;
    color: @footer-text-color;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 0 94px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";