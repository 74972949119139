@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

.table-action:not(.ant-dropdown-menu-item) {
  display: flex;
  align-items: center;
  min-height: 28px;
  max-height: 36px;
  color: @link-complement-light;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 12px;
  }

  &.ant-btn {
    .button-primary();

    &.default {
      .button-default();
      .button-default-hover();
    }

    &.critic-action {
      .button-critic();
    }
  }

  &.submenu-button {
    .button-default();
    .button-default-hover();
  }

  &.active {
    background: @white !important;
    color: @link-complement-light !important;
    border: 1.5px solid @link-complement-light !important;
    .set-svg-color(@link-complement-light);
  }

  &.filter-button {
    .button-default();
    .button-default-hover();
  }
}


.ant-dropdown {
  position: absolute;
  z-index: 1000;
}

.button-redirect {
  margin-left: 12px;

  &__table-action {
    .button-default();
    .button-default-hover();
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";