@import 'src/themes/default/default';

&.svc-main-page-section-title {
  width: 100%;
  display: flex;
  align-items: center;
  color: @text-accent-dark;

  &:not(:first-child) {
    margin: 24px 0 24px 0;
  }

  &:first-child {
    margin: 0 0 24px 0;
  }

  &>div:first-child {
    margin-right: 16px;
    font-size: 24px;
    font-weight: 400;
  }

  &__separator {
    flex-grow: 1;
    height: 1px;
    border-top: 1px #E0E4F0 solid;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";