@import 'src/themes/default/default';

.table-report {
  &__table {
    .table-report-string-column {
      word-break: break-word !important;
      white-space: pre-line !important;
    }

    .ant-table-column-sorters {
      width: 100%;

      >span:first-child {
        width: 100%;
      }
    }

    &__filter-icon {
      display: inline-block;
      pointer-events: auto;

      &_open {
        &>svg {
          fill: @complement-light !important;
        }
      }

      &>svg {
        fill: currentColor;
        cursor: pointer;
        margin-bottom: -2px;
        margin-left: 10px;

        &:hover,
        &:focus {
          fill: @complement-light;
        }
      }
    }

    &__filter-popover {
      &-overlay {
        .ant-popover-inner {
          box-shadow: unset;
          background-color: #fafcfe;

          &-content {
            border: 2px solid #E0E4F0;
            border-radius: 6px;
          }
        }

        .ant-popover-arrow {
          margin-top: 1px;
        }

        &.ant-popover-placement {
          @arrow-color: @gray-400;

          &-bottomLeft {
            & .ant-popover-content {
              margin-left: -2px;
            }

            .ant-popover-arrow {
              margin-left: -2px;
              border-top-color: @arrow-color;
              border-left-color: @arrow-color;
            }
          }

          &-bottomRight {
            & .ant-popover-content {
              // margin-right: -12px;
            }

            .ant-popover-arrow {
              margin-right: -12px;
              border-top-color: @arrow-color;
              border-left-color: @arrow-color;
            }
          }
        }
      }
    }


    &__column-title {
      white-space: pre-wrap;
      min-width: min-content;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .ant-badge {
        color: currentColor;

        &-count-sm {
          font-size: 8px;
          min-width: 10px;
          height: 10px;
          line-height: 10px;
        }

        &-multiple-words {
          padding: 0px 2px;
        }

        &-count {
          background: @faint-dark;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";