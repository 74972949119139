@import 'src/themes/default/default';
@import 'components/document-upload-template/document-upload-template.mixins';

.request-additional-placement {
  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 4px 0 24px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--grey_stroke, #E0E4F0);
  }

  &-section {
    &__body {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 16px;
      gap: 16px;
      margin-bottom: 16px;

      .ant-form-item {
        margin: 0;

        .ant-form-item-label {
          display: flex;
          height: 50px;
        }
      }
    }

    &__title {
      display: flex;
      font-size: 24px;
      font-weight: 400;
      padding: 0;
      line-height: 28px;
      color: #000000;
      margin: 26px 0;

      &::after {
        content: '';
        display: inline-block;
        flex-grow: 1;
        height: 1px;
        background-color: #E0E4F0;
        margin: auto 0 auto 16px;
      }
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";