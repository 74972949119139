@import 'src/themes/default/default';

.table-row-attachments {
  display: flex;
  justify-content: center;
  align-items: center;

  &__pin-requests {
    padding: 0;
    cursor: pointer;
    color: #404040;
    opacity: .8;
    margin-left: 6px;

    &:hover {
      color: #404040;
      opacity: 1;
    }

    &:active {
      color: #404040;
      opacity: 1;
    }

    &:focus {
      color: #404040;
      opacity: 1;
    }

  }

  &__row-controls {
    display: flex;
    gap: 6px;
    align-items: center;
    opacity: .8;
    cursor: pointer;
    color: #404040;

    // &_is-uploading {
    // cursor: default;
    // opacity: 0.7 !important;
    // }

    &:hover {
      opacity: 1;
    }

    .ant-badge {
      color: currentColor;

      &-count-sm {
        font-size: 8px;
        min-width: 10px;
        height: 10px;
        line-height: 10px;
      }

      &-multiple-words {
        padding: 0px 2px;
      }

      &-count {
        background: #404040;
      }
    }
  }

  &__files-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 32px;
  }

  &__attachments {
    display: flex;
    flex-direction: column;
    // gap: 8px;
    // margin-bottom: 8px;

    &__item {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      position: static;
      height: unset;
      border: 1px solid #E0E4F0;
      border-radius: 3px;
      padding: 9px;

      &__file {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          height: 18px;
          width: 18px;
        }

        &__name {
          &_in-deletion-list {
            color: @gray-500;
            text-decoration: line-through;
          }
        }
      }

      &__actions {
        display: flex;
        gap: 16px;

        &__action {
          user-select: none;
          display: flex;
          gap: 8px;
          align-items: center;
          color: @link-complement-light;
          cursor: pointer;

          &:hover {
            color: @input-focused-light;
          }

          &_loading {
            cursor: default;
          }
        }

        &__icon {
          svg {
            fill: @faint-dark;
          }
        }

      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";