.progress-list-statistics__body__item__progress-bar {
    width: 137px;
    height: 8px;
    background: #E0E4F0;
    border-radius: 1px;
    margin: 0px 8px;

    &__bar {
        height: 8px;
        border-radius: 1px;
        background-color: @link-complement-light;
        max-width: 100%;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";