.forbidden-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  .auth-page {
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";