@import 'src/themes/default/default';

.search {
  height: 36px;

  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  &__wrapper {
    margin-left: 10px;
    background: white;
    height: 100%;
    width: 100%;
    min-width: 100px;
    max-width: 469px;

    &:hover {
      text-decoration: none;
    }
  }

  &__container {
    height: 100%;
    border: 1px solid @line-faint-light;
    border-radius: 5px;

    &:hover {
      border: 1px solid @input-focused-light;
    }
  }

  .ant-input {
    &-prefix {
      margin-right: 0;
    }

    &-affix-wrapper-focused {
      border: 1px solid @input-focused-light;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &.ant-form-inline {
    .ant-form-item {
      margin-right: 0;
    }
  }
}

.search:last-child {
  margin-right: 15px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";