@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.select-field {
  // ============ Select field properties ========
  border-radius: 3px;
  min-height: 36px;
  outline: none;
  width: 100%;

  .ant-select-selector {
    min-height: 36px !important;
    border: 1px solid @line-faint-light;
    border-radius: 3px !important;
    box-shadow: none !important;

    .ant-select-selection-item {
      margin-top: 2px;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      box-shadow: none !important;
      border: 1px solid @input-focused-light;
    }

    .ant-select-selection-placeholder {
      margin-top: 2px;
      color: @faint-dark;
    }
  }

  &.ant-select-open .ant-select-selector {
    border: 1px solid @input-focused-light !important;
  }

  .caret-icon {
    color: @text-gray-200;

    &.open {
      transform: rotate(180deg);
    }
  }

  &__option {
    padding: 0;
    margin: 0 12px;
    font-weight: 400;
    background: white !important;

    .ant-select-item-option-content {
      border-radius: 3px;
      padding: 4px 8px 4px 4px;
      margin-left: 4px;

      &:hover {
        background: @gray-200;
      }
    }

    &.ant-select-item-option-selected {
      background: white;
      background: @gray-200;
      font-weight: 400;
    }

    .highlighted {
      color: @link-complement-light;
    }
  }

  &.ant-select-disabled {

    .ant-select-selector {
      background: @gray-100 !important;
    }

    .ant-select-selection-item {
      color: @gray-500;
    }

    .ant-select-selector:hover {
      border-color: @input-focused-light !important;
    }
  }

  // ============ Multiple select properties ========
  &__tag {
    height: 22px;
    background: white;
    border: 1px solid @line-faint-light;
    border-radius: 3px;

    font-size: 12px;
    color: @faint-dark;
  }

  &__clear-icon {
    height: 14px;
    width: 14px;

    padding-top: 6px;
    stroke: @faint-dark;
  }

  .suffix-icon {
    position: absolute;
    top: -3px;
    left: 0;
  }
}

// ============ Select dropdown properties ========
.ant-select-multiple,
.ant-select-dropdown {
  .select-field__option {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-weight: 400;
    background: white;

    &.ant-select-item-option-selected {
      background: white;
    }

    &:hover {
      background: white;
    }

    .ant-select-item-option-content:hover {
      background: @gray-100;
    }
  }
}

.ant-select-multiple {
  .ant-select-selector {
    overflow-x: auto;
    -ms-overflow-x: auto;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";