@import "src/themes/default/default";

.file-upload-cell {

  .action {
    cursor: pointer;
    color: @text-gray-400;
    display: flex;
    align-items: center;
    gap: 0 4px;

    &:hover {
      color: @black-0;
    }

    svg {
      margin-top: -2px;
      fill: @gray-icon;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";