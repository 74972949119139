@import "../../../../shared/styles/mixins";

.file-upload-modal {
  .modal-header();
}

.type-width-limit {
  max-width: 400px;
}

.file-upload-table {
  position: relative;

  .ant-table-content {
    .scroll();
  }

  .ant-table-cell {
    padding: 7px 8px;

    .ant-form-item-explain-error {
      display: none;
    }
    .ant-form-item-with-help {
      max-height: 36px;
      overflow: hidden;
    }
  }

  &__actions {
    position: absolute;
    bottom: 15px;
    right: 0;
    width: 500px;
    display: flex;
    justify-content: flex-end;

    &.relative {
      position: unset;
      margin-top: 10px;
      width: 100%;
    }

    &__upload-button {
      &.ant-btn-primary {
        &:focus {
          color: @base-light;
          background-color: @complement-light;
          border: 1.5px solid @faint-light;
          box-shadow: 0 1px 2px @gray-shadow;

          .set-svg-color(@base-light);
        }

        &:hover {
          background: @base-light;
          color: @complement-light;
          border: 1.5px solid @complement-light;

          .set-svg-color(@complement-light);
        }
      }
    }

    &__clear-button {
      &.ant-btn-default {
        &:focus {
          background: @base-light;
          color: @complement-light;
          border: 1.5px solid @complement-light;
        }

        &:hover {
          background-color: @complement-light;
          border: 1.5px solid @faint-light;
          color: @base-light;
          box-shadow: 0 1px 2px @gray-shadow;

          .set-svg-color(@base-light);
        }
      }
    }
  }

  &__alert {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;

    height: 46px;
    background: @white;
    box-shadow: 0 0 4px rgba(42, 43, 55, 0.1);
    border: 0;
    padding: 16px 16px 16px 20px;
    border-radius: 3px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 7px;
      border-radius: 3px 0 0 3px;
      background: @red-600;
    }

    .ant-alert-icon {
      position: initial;
      top: unset;
      left: unset;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";