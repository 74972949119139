@import "src/themes/default/default";
@import 'src/shared/styles/mixins';

.ais-documents-interface {
    &__filters {
        width: 100%;
    }

    &-table {
           .ant-table-placeholder {
            height: 110px;
        }
    }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";