@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

@default-color: hsl(0, 0%, 92%);

@search-margin: 18px;
@items-gap: 14px;

.new-side-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: OpenSans;
  flex-grow: 1;
  width: 46px;
  background-color: @accent-dark;
  overflow: hidden;

  &__curve {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 60px;
    left: 46px;
    background: radial-gradient(circle at bottom right,
          transparent 30px,
          @accent-dark 31px,
          @accent-dark 31px,
          @accent-dark 31px,
          @accent-dark 31px,
          @accent-dark 30px);
  }

  &__tooltip {
    .ant-tooltip-inner {
      white-space: nowrap;
    }

    .ant-tooltip-content {
      margin-left: 10px;
      width: max-content;
    }

    .ant-tooltip-arrow {
      margin-left: 10px;
    }
  }

  &-round-border {
    position: absolute;
    z-index: 100;
    left: 10px;
    top: 38px;
  }

  &__input {
    margin: @search-margin 18px @search-margin 18px;
    width: 84%;

    .ant-input {
      color: white;

      &::placeholder {
        color: white;
      }

      &-suffix {
        span {
          color: white;
          opacity: .9;
        }

        ;
      }

      &-affix-wrapper {
        border: 1px solid white;
        background: @complement-light;
        opacity: .8;

        &:hover {
          opacity: 1;
        }
      }

      &-prefix {
        color: white;
      }
    }
  }

  &__expand {
    width: 28px;
    height: 28px;
    background-color: @complement-light;
    border-radius: 200px;
    position: absolute;
    top: 46%;
    right: -16px;
    color: @default-color;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: margin-right .2s ease-in-out;

    svg {
      transition: transform .2s linear;
    }

    &_open {
      background-color: @accent-dark;
      margin-right: -338px;

      svg {
        transform: rotate(180deg);
      }
    }

    &:hover {
      color: white;
    }
  }

  &__dark-bg {
    width: 100vw;
    height: 100vw;
    z-index: -2;
    position: fixed;
    background: @background-faint-dark;
    opacity: .2;
  }

  &__list {
    .scroll();
    display: flex;
    flex-direction: column;
    font-size: 14px;

    &_main {
      padding: 0px 36px 24px 20px;
      flex-grow: 1;
      gap: @items-gap;
      overflow: auto;
    }

    &__item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 32px;
      min-height: 24px;

      a {
        color: unset !important;
      }

      &_child {
        padding: 6px;
      }

      &_depth-1 {
        &:first-child {
          margin-top: -6px;
        }

        &:last-child {
          margin-bottom: -6px;
        }
      }

      flex-shrink: 0;
      color: @default-color;

      &_selected {
        background-color: @context-color-hover;
      }

      &__arrow {
        transition: transform .2s linear;

        &_rotated {
          transform: rotate(180deg);
        }
      }

      &__item-title {
        &_selected {
          font-weight: 500;
          color: white;
        }
      }

      &:hover {
        color: white;
      }
    }
  }

  &__body {
    @mg: 60px;
    transition: transform .2s ease-in-out;
    top: @mg;
    left: 46px;
    min-width: 340px;
    height: calc(100vh - @mg);
    background-color: @complement-light;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &_closed {
      transform: translateX(-100%);
    }
  }

  &__logo {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    position: absolute;
    z-index: 100;
    left: 2px;
    top: 2px;

    svg {
      height: 52px;
    }
  }

  &__partner-logo {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    position: absolute;
    z-index: 100;
    left: 2px;
    top: 2px;

    img {
      width: @header-partner-logo-width;
      height: @header-partner-logo-height;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 8px;
    flex-grow: 1;
    gap: @items-gap;
    justify-content: flex-start;
    color: black;
    overflow: scroll;
    transition: margin-top .2s ease-in-out;
    margin-top: @search-margin + 106px;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      color: @base-light;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border-radius: 3px;
      cursor: pointer;
      flex-shrink: 0;

      svg {
        transition: filter .1s linear;
        opacity: .7;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }

      &_selected {
        background-color: @base-light;
        color: @icon-dark;

        svg {
          opacity: 1;
        }
      }
    }
  }


  // === Footer ===
  &__footer {
    font-size: 14px;
    padding: 8px;
    margin-bottom: 4px;

    &__separator {
      margin: 16px 0px;
      width: 100%;
      height: 1px;
      background-color: @base-light;
      opacity: .7;
    }

    &__label {
      display: none;
    }
  }

  &__body {
    .new-side-menu__footer {
      &__separator {
        background-color: @base-light;
        opacity: .7;
      }

      &__icon {
        display: none;
      }

      &__label {
        font-weight: 500;
        display: block;
        line-height: 24px;
        cursor: pointer;
        color: @default-color;

        &:hover {
          color: white;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";