@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.fake-input {
  .input-disabled();
  // hide cursor
  caret-color: transparent;
  color: transparent;

  &.fake-disable {
    cursor: pointer;
    background: white;

    .ant-input {
      background: transparent;
      cursor: pointer;

      &.ant-input-disabled {
        border-color: transparent;
        background: transparent;
        color: black;
      }
    }

    &:hover {
      border: 1px solid @blue-600;

      svg {
        fill: @link-complement-light;
      }
    }
  }

  svg {
    transition: color .4s linear;
    fill: @gray-icon;
    cursor: pointer;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";