@import 'src/themes/default/default';

.ais-single-value-widget {
  display: flex;
  // justify-content: center;
  padding: 16px 8px;
  border: 1px solid @faint-dark;
  border-radius: 8px;
  background-color: @gray-100;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  sup {
    color: red;
  }

  &__title {
    font-size: 14px;
    color: @faint-dark;
    font-weight: 500;
  }

  &__values {
    display: flex;
    gap: 6px;
    white-space: nowrap;

    sup {
      margin-left: 4px;
    }


    &__sup {
      color: #1054B8;
      font-weight: 500;
    }

    &__main {
      font-size: 40px;
      font-weight: 600;
    }
  }

  &__footer {
    font-size: 12px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";