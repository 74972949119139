@import "../../../../../shared/styles/mixins";
@import "src/themes/default/default";

.file-field-info {

  .input();
  display: flex;
  gap: 0 7px;
  padding-right: 5px;
  padding-left: 5px;

  .download-icon {
    margin-left: auto;
    cursor: pointer;
  }

  .file-name {
    &.text {
      width: 100%;
      text-align: left;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      empty-cells: hide;
      color: @text-gray;
      ;
      padding: 0;
      margin: 0;
    }
  }


  .delete-icon {
    margin-right: 5px;
    cursor: pointer;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";