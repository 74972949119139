.monitor-service-content-displayer-modal {
  &__content {
    min-height: 200px;
    padding: 20px;

    &-error {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ant-empty-image {
        height: auto;
      }
      svg {
        width: 25px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";