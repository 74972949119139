.ais-operations-protocol-dashboard {
    &__widgets {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;

        > div {
            flex-grow: 1;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";