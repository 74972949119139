.set-input-value-modal {
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-input {
      resize: none;
      height: 43.75rem;
    }

    .buttons {
      &_as-row {
        flex-wrap: unset;
        gap: 8px;
        .ant-space-item,
        .ant-btn {
          margin-right: 0px !important;
          width: 100%;
        }
      }

      .ant-space-item,
      .ant-btn {
        width: 100%;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";