@import 'src/themes/default/default';

.dd-parameters-list-item {
  border: 1px solid @line-faint-light;
  padding: 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;

  &__item-title {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 500;

    .ant-form-item {
      margin-bottom: 0px;
    }

    &__buttons {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";