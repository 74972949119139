@import 'src/themes/default/default';
@import 'src/shared/styles/mixins.less';

.table-report-pagination {
  .set-flex(@direction: row);
  gap: 12px;

  .page-jumper {
    &__input {
      padding: 0;
      text-align: center;
    }
  }

  &__button {
    .set-flex(@direction: row, @justify: center);

    width: 36px;
    height: 36px;
    padding: 7px;
    border: 1px solid @line-faint-light !important;
    border-radius: 3px;
    transition: none;

    &-icon {
      .set-flex(@direction: row);
      width: 12px;
      height: 12px;

      svg {
        width: inherit;
        height: inherit;
      }
    }

    &:hover {
      color: @complement-light;
    }

    &:disabled,
    &[disabled] {
      background-color: #f7f7f7 !important;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";