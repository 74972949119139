.file-uploader {
    &__multiple-fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__multiple-choose-btn {
        align-self: flex-start;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";