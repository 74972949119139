@import 'src/shared/styles/mixins'; 

.custom-table {

    .ant-table {
        overflow: unset !important;

        &-placeholder {
            height: 160px;

            > td:first-child {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border: 0 !important;
                background: unset !important;
            }
        }

        &-container {
            &::after {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 5px;
            }
            &::before {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 5px;
            }
        }

        &-content {
            border-radius: 5px;
            overflow: auto;
            margin-bottom: -1px;
            .scroll();
        }


    }    

    .ant-table-bordered {
        > .ant-table-container {
            border: 0px !important;

            .ant-table-tbody {
                td {
                    border-right: 0 !important;
                }
            }
            .ant-table-thead {
                th[rowspan]:last-child:not(.own-borders) {
                    border-right: 0 !important;
                }
                th:last-child:not(.own-borders) {
                    border-top-right-radius: unset !important;
                }
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";