@import 'synthesis-document.mixins';
@import 'src/shared/styles/mixins.less';

.synthesis-document-form {
  &_hidden {
    display: none;
  }

  &__buttons-bar {
    .buttons-bar-mixin();
    margin-bottom: 24px;
  }

  .synthesis-document-section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__title {
      .section-title-mixin();
      font-size: 14px;
      font-weight: 600;
      margin: 16px 0;
    }

    &__body {
      .form-flex-mixin();

      &__row {
        &__extra {
          font-size: 12px;
          color: @error
        }
      }
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";