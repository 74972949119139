@import '../../../../../node_modules/antd/lib/style/themes/default.less';
@import "src/themes/default/default.less";

.upload-list-wrapper {
  position: relative;

  &__dropzone {
    border: 1px dashed @gray-500;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    padding: 12px;
    background-color: rgba(#eff1f6, .3);
    margin-top: 16px;

    &__icon {
      display: flex;
      justify-content: center;
      margin-bottom: 4px;

      span[role="img"] {
        vertical-align: unset;
      }
    }

    &:hover {
      background-color: rgba(#eff1f6, .4);
    }
  }

  &_with-dropzone {
    display: flex;
    flex-direction: column-reverse;

    .ant-upload.ant-upload-select.ant-upload-select-text {
      width: 100%;
    }
  }

  .ant-upload-list-item {
    position: static;
    height: unset;
    border: 1px solid @line-faint-light;
    border-radius: 3px;
    padding: 9px;

    &:hover .ant-upload-list-item-info {
      background-color: transparent;
    }

    .ant-upload-list-item-info {
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      >span {
        display: flex;
        gap: 0 6px;
        align-items: center;

        .ant-upload-text-icon {
          position: relative;
          top: -3px;
          height: 16px;
        }

        .ant-upload-list-item-name {
          padding: 0;
          line-height: 130%;
        }

        .ant-upload-list-item-card-actions {
          &-btn {
            opacity: 1;
            right: 5px;
            padding-top: 4px;

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";