@import "src/themes/default/default";

.g-context-filter-preview {
  &__content {
    display: flex;

    &__form-item-tax {
      display: flex;

      &__svg {
        margin-top: 6px;
        color: @base-light;
      }
    }

    .ant-form {
      display: flex;
      gap: 22.12px;
      align-items: center;

      .ant-row {
        margin: 0 !important;
        min-width: 220px;
        border: none;

        .ant-select-selector {
          border: none !important;
          background-color: @accent-dark !important;
          color: white;
          font-size: 16px;
          font-weight: 300;

          .ant-select-selection-item {
            color: white !important;
          }
        }

        .ant-select {
          .ant-select-arrow {
            svg {
              color: white;
            }
          }
        }


        .ant-select-open {
          .ant-select-arrow {
            svg {
              color: white;
            }
          }
        }
      }
    }
  }
}

.custom-dropdown {
  padding: 5px 12px;
  background-color: @complement-light !important;
  border: 1px solid @complement-light;

  .ant-select-item {
    background-color: @complement-light !important;
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin: 0;

    .ant-select-item-option-content {
      border-radius: 0;
      padding: 8px;
      background-color: @complement-light !important;

      &:hover {
        background: @context-color-hover !important;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";