@import 'src/themes/default/default';

.aside-dropdown {
  display: none;

  &.open {
    display: flex;
    flex-direction: column;
  }

  &__divider {
    height: 1px;
    background-color: @divider-gray;
    margin: 8px 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";