@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.form-drawer {
  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }

  .ant-form-vertical {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-drawer-content {}

  .ant-card-bordered {
    height: 100%;
  }

  .ant-card {
    border: 0;

    .ant-card-body {
      height: 100%;

      .ant-form-vertical {
        height: 100%;
        justify-content: space-between;

        .form-body {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: hidden;
          justify-content: space-between;

          .form-fields {
            display: flex;
            overflow: auto;
            min-height: 500px;
            .scroll();

            .field,
            .ant-form-item {
              height: fit-content;
            }
          }

          .form-actions {
            position: relative;
            margin: 0;
            padding-right: 6px;
            height: unset;
          }
        }
      }
    }
  }

  .ant-drawer-header {
    margin: 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid @line-faint-light;
  }


  .ant-card-body {
    padding: 24px 16px;
  }

  .ant-drawer-title {
    font-weight: 600;
    font-size: 16px;
    padding: 24px 0 0;
    line-height: 20px;
    color: @black-3;
  }

  .ant-modal-close-x {
    position: relative;
  }

  .close-icon {
    position: absolute;
    top: 24px;
    right: 0;
    stroke: @gray-900;
  }

  .ant-drawer-content-wrapper {
    width: 1000px !important;
  }
}

@media screen and (max-width: 1200px) {
  .form-drawer {
    .ant-drawer-content-wrapper {
      width: 60.75rem !important;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";