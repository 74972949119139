@import 'src/themes/default/default';

.progress-list-statistics__body__item {
  display: grid;
  align-items: center;
  grid-template-columns: auto max-content 30px;

  overflow: hidden;
  white-space: nowrap;

  &__title {
    cursor: pointer;
    color: @faint-dark;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__popover {
    max-width: 350px;

    &.hidden {
      display: none;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";