@import 'src/themes/default/default';
@import '../widgets.less';

.circle-statistics {
  .widget-mixin();
  min-height: 204px;
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 461px;

  &.empty {
    justify-content: center;
    min-width: 250px;
  }

  &__description {
    margin-left: 32px;

    &__item-row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &__border {
        height: 18px;
        width: 4px;
        border-radius: 1px;
        margin-right: 8px;
      }

      &__item {
        &__title {
          margin-right: 8px;
          color: @faint-dark;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";