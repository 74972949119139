@import 'src/themes/default/default';

.dd-parameters-rule-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid @line-faint-light;
  border-radius: 3px;
  padding: 16px;
  flex-shrink: 0;
  flex-basis: 30%;

  &__body {
    display: flex;
    gap: 8px;

    &_vertical {
      flex-direction: column;
      gap: 15px;
    }

    &:not(&_vertical) {
      .ant-form-item {
        flex-basis: 50%;
      }
    }

    .ant-form-item {
      .ant-select-selection-placeholder {
        margin-top: 3px;
      }

      min-width: 0;
      flex-shrink: 0;
      flex-grow: 1;
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;

    &_vertical {
      align-self: center;
      font-weight: 500;
    }

    &:not(&_vertical) {
      div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;

        &::after {
          display: flex;
          content: '';
          flex-grow: 1;
          height: 1px;
          background-color: @line-faint-light;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";