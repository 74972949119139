@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.quill {
  .ql-tooltip {
    .shadow();
    width: 38.75rem;
    flex-direction: column;
    border: 1px solid @line-faint-light;
    box-shadow: none;
    padding: 16px;
    z-index: 1000;
    position: absolute;
    left: 20% !important;
    top: 0 !important;
  }

  .ql-tooltip:not(.ql-hidden) {
    display: flex;
  }

  // ===== Link creation modal =====
  .ql-tooltip:not(.ql-editing) {
    display: block;

    &::before {
      content: "Перейти по ссылке:";
    }

    .ql-preview {
      display: block;
      width: 100%;
      max-width: unset;
    }

    a.ql-action {

      &::after {
        .button-primary();
        content: "Редактировать";
        margin-left: 0;
        width: calc(50% - 8px);
        display: inline-flex;
      }
    }

    a.ql-remove {

      &::before {
        .button-default();
        .button-default-hover();
        content: "Удалить";
        margin-left: 16px;
        width: calc(50% - 8px);
        display: inline-flex;
      }
    }
  }

  // ======= Link editing =====
  .ql-tooltip.ql-editing {
    input {
      outline: none;
      .input();
      margin-bottom: 16px;
    }

    &::before {
      content: "URL-адрес";
    }

    a.ql-action {
      &::after {
        .button-primary();
        content: "Сохранить";
        margin-left: 0;
      }
    }
  }

  .ql-tooltip.ql-hidden {
    display: none;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";