@import "src/themes/default/default";

.table-report-search {
  display: flex;

  .ant-form-item {
    margin-bottom: 0px;
  }

  &__controls {
    display: flex;
    align-items: center;

    &__selection-info {
      span {
        font-weight: 500;
      }
    }

    &__selection {
      margin-left: 12px;
    }

    &__buttons {
      margin-left: 12px;

      &>button {
        width: 36px;
        height: 36px;
        padding: 7px;
        border: 2px solid @gray-icon !important;
        border-radius: 5px;

        &:hover {
          background-color: #f9f9f9;
        }

        &:disabled,
        &[disabled] {
          background-color: #f7f7f7 !important;
        }

        &:first-child {
          margin-right: 4px;
        }

        &:disabled {
          background-color: hsl(216, 26%, 98%)
        }

        &:focus:not(:hover) {
          outline: none;
          box-shadow: none;
          color: #595959;
          border-color: #E0E4F0;
        }

        color: #595959;
        height: 36px;
      }
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";