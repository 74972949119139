@import "src/themes/default/default";

.foreign-parents-fields {
  flex: 49% 0;
  min-width: 500px;
  padding: 22px 24px;
  border: 1px solid @line-faint-light;
  border-radius: 8px;

  @media screen and (max-width: 1480px) {
    flex-grow: 1;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";