@import "src/themes/default/default";
@import "src/shared/styles/mixins.less";

.change-password-form {
  .auth-form();

  z-index: 2;

  &__fields {
    .auth-fields();
  }

  &__error {
    .auth-error();
  }
}

.change-password-form-tooltip {
  .auth-tooltip();
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";