@import "src/themes/default/default";

.custom-tooltip {
  background-color: #141414;
  border-radius: 2px;
  opacity: 0.8;
  color: @white;
  padding: 8px;
  white-space: pre-line;
}

.nob-components-bar-chart-widget {
  border-radius: 10px;
  max-height: 540px;

  .ant-card-body {
    padding: 16px;
  }
}

.nob-components-bar-chart-widget__title {
  margin-bottom: 0 !important;
}

.nob-components-bar-chart-widget__select {
  min-width: 150px;
  width: 150px;
}

.nob-components-bar-chart-widget__select-label {
  margin-bottom: 5px !important;
}

.nob-components-bar-chart-widget__chart {
  margin: 0 auto;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";