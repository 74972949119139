@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

// =========== Input =========
.form {
  .ant-input {
    .input();

    &.ant-input-disabled {
      .input-disabled();
      .hide-extra-border();
    }
  }

  .ant-input-affix-wrapper {
    &:hover {
      border: 1px solid @line-faint-light;
    }
  }

  .ant-input--soft-disabled {
    .input();
    cursor: default;
    background: white;

    .ant-input {
      background: transparent;
      cursor: default;

      &.ant-input-disabled {
        border-color: transparent;
        background: transparent;
        color: black;
      }
    }
  }


  .textarea.ant-input {
    width: 100%;
  }

  .field {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: baseline;

    &.no-display {
      display: none;
    }

    .ant-form-item-explain.ant-form-item-explain-error>div:not(:first-child) {
      display: none;
    }

    .extra-borders {
      border-left: 1px solid @line-faint-light;
      border-right: 1px solid @line-faint-light;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";