@import '../../report-configuration.mixins.less';
@import '../../../../shared/styles/mixins.less';

.report-header-settings {
    &__buttons-bar {
        .buttons-bar-mixin();
        margin-bottom: 24px;
    }

    &__section-title {
        .section-title-mixin();
        &::after {
            margin-left: 0;
        }
    }
}

.form-list {
    .form-list-mixin();
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";