@import "src/themes/default/default";

.grade {
  display: flex;
  margin-left: 8px;
  gap: 8px;
  padding-bottom: 3px;
}

.grade-button {
  border: 0;
  padding: 0;
  box-shadow: none !important;
  background: @white;
  width: 20px;
  height: 20px;

  &:hover {
    background: @white;
    border: 0;

    svg {
      fill: @secondary-blue;
    }
  }

  &:active {
    box-shadow: none !important;
  }

  &.disabled {
    cursor: default;

    svg {
      fill: @secondary-blue;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";