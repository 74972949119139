@import 'src/themes/default/default';

.crypto-pro-signing-modal {
  &__sign-component {
    color: @gray-500;

    &:hover {
      color: @faint-dark;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";