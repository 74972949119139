.setting-program-template{
  .ant-card-bordered {
    border: none;
  }
  .ant-card-body {
    padding: 0;
    margin:0;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";