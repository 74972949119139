@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.no-display {
  display: none;
}

.property-settings-form {
  overflow-x: auto;
  min-height: 100%;
  .scroll();
  padding: 24px 0 24px 20px;

  .property-settings-button {
    display: flex;
    gap: 16px;
  }

  .ant-input-affix-wrapper-disabled:hover {
    border-color: @line-faint-light !important;
  }

  .spinner-container {
    margin-top: 50%;
  }
}

.form-page {
  overflow-x: auto;

  .form.ant-form>div {
    display: flex;
    flex-direction: column-reverse;
  }
}

.field-block {

  border: 0;

  .ant-card-head {
    .card_head();
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 24px;

    .ant-card-head-title {
      font-size: 20px;
    }
  }

  .ant-card-body {
    min-width: 1356px;
    padding: 0;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;


    .ant-form-item {
      margin-bottom: 0;
    }

    &:before,
    &:after {
      display: none;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";