@import "src/themes/default/default";

.monitor-services-request-content {
  color: @link-complement-light;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";