@import '../../../../shared/styles/mixins.less';

.report-ks-settings {
  .row {
    .set-columns(@width: 50%);
    > * {
      margin-right: 8px;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";