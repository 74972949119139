@import 'src/components/personal-account/personal-account.mixins.less';

.general-settings {
  .general-settings-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;

    &__buttons {
      .buttons();
      margin-bottom: 16px;
    }

    &__fields {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 16px;
    }
  }

  .ant-table-cell {
    min-width: 300px;
  }

  .ant-select-selector {
    min-width: 200px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-table-container {
    display: inline-block;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";