@import "src/themes/default/default";

.file-column {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  text-decoration: none;

  .line-break {
    width: 100%;
  }

  &__input {
    display: none;
  }

  &__empty {
    flex-wrap: nowrap;
    color: #B7C1D4;

    &:hover {
      color: @black;

      .file-column__icon.empty {
        color: @black;
      }
    }
  }

  &__title {
    white-space: nowrap;

    &:hover {
      color: @black;
    }
  }

  &__icons {
    display: flex;
    flex-wrap: nowrap;
    padding-top: 5px;
  }

  &__icon {
    color: @gray-icon;
    fill: @gray-icon;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    &.empty {
      margin-right: 7px;
    }

    &:hover {
      color: @file-inactive;
      fill: @file-inactive;
    }
  }

  &.signature {
    flex-wrap: nowrap;
  }
}

.file-column__input {
  display: none;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";