@import "src/themes/default/default";

.report-watcher {
  display: flex;
  align-items: center;
  color: @default-gray;
  cursor: pointer;

  &.disabled {
    color: @black;
    cursor: default;
  }

  &__icon {
    margin-right: 7px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";