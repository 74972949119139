@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.filter-drawer {
  .ant-drawer-content-wrapper {
    width: 416px;
  }

  .ant-drawer-header {
    margin: 24px 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 1px solid @line-faint-light;

    .ant-drawer-title {
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: @text-gray;
    }
  }

  .ant-drawer-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .scroll();

    .ant-form-vertical {
      display: flex;
      flex-direction: column;
      height: 100%;

      .ant-form-item {
        width: 380px;
        margin-bottom: 16px;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    stroke: @gray-900;
  }
}

.filter-drawer {
  .drawer-footer {

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.drawer-footer {
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  .ant-space-item {
    &:not(:first-child) {
      margin-left: 8px;
    }

    &:not(:last-child) {
      width: 129px;
    }

    .save-action-btn,
    .delete-action-btn {
      .button-primary();
      width: 100%;
    }

    .simple-action-btn {
      .button-default();
      .button-default-hover();
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";