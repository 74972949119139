@import '~antd/dist/antd.less';
@import "src/themes/default/default";
@import './mixins';
@import './common';
@import './ant-theme-override';

body {
  font-size: 16px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  font-size: 16px;
}

@media screen and (max-width: 1500px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 11px;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: @link-complement-light !important;
}

.ant-list,
.ant-card-head,
.ant-typography,
.ant-modal-title,
.ant-select-selection-item {
  color: @text-gray;
}

.message-text {
  margin: 30px 0 20px;
  line-height: 1.3;
}

.main__content-content {
  padding: 30px;
  background: #ececec;
}

.form__item {
  position: relative;
  top: 0.5rem;
}

.form__item-bottom {
  position: relative;
  top: 3rem;
}

.ant-input-affix-wrapper {
  .input();
  box-shadow: none !important;

  .ant-input {
    background-color: transparent;
    border-color: transparent;
    box-shadow: unset;

    &:hover,
    &:focus {
      border-color: transparent;
      background-color: transparent;
    }
  }
}

// ===== Fonts =====

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), local("Open Sans"), url("../assets/_fonts/open-sans.ttf") format("truetype");
}

// ===== Label =====
.ant-form-item-label>label:not(.ant-form-item-required-mark-optional):not(.ant-form-item-required)::before {
  // Фикс прыгающей формы, если поле для ввода становится обязательным
  // max-width: 0px;
  // content: '\0000a0';
  font-family: SimSun, sans-serif;
}

.ant-form-item-label {
  display: flex;
}

// ===== Buttons ======
.ant-btn-primary {
  .button-primary();
}

.ant-btn-default {
  .button-default();
  .button-default-hover();
}

.ant-btn-ghost {
  .button-default();
  // .button-default-hover();

  border: none;
  box-shadow: none;

  &:active,
  &:focus,
  &:hover {
    background-color: none;
    border: none;
    box-shadow: none;
    color: @text-accent-dark;

    .set-svg-color(@text-accent-dark);
  }

  &:disabled {
    opacity: 0.5;
    color: @faint-dark;
    background: none;

    .set-svg-color(@faint-dark);
  }

  &:disabled:active,
  &:disabled:hover {
    color: @faint-dark;
    background: none;

    .set-svg-color(@faint-dark);
  }
}

.btn-only-icon {
  &.ant-btn {
    padding: 0 !important;
    min-width: 38px;

    & svg {
      margin-left: 0 !important;
    }
  }

  &.ant-tooltip-disabled-compatible-wrapper {
    .ant-btn {
      padding: 0 !important;
      min-width: 38px;

      & svg {
        margin-left: 0 !important;
      }
    }
  }
}

.ant-btn-sm {
  height: 26px !important;
  padding: 0 10px;
  width: unset !important;
  min-width: 26px !important;
}

.button-critic {
  .button-primary();
  background: @critic-dark;
}

// ======== Checkbox ======
.ant-checkbox {
  border-radius: 2px;

  .ant-checkbox-inner {
    border: 2px solid @line-faint-light;
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 2px solid @complement-light !important;
      background-color: @complement-light !important;
    }
  }
}

.ant-checkbox:not(.ant-checkbox-indeterminate) {
  .ant-checkbox-inner::after {
    left: 20% !important;
  }
}

.ant-checkbox-wrapper-disabled {
  opacity: 0.5;

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      &::after {
        border-color: @white;
      }
    }
  }
}

// ============ Input ============
.ant-input {
  &:-webkit-autofill {
    -webkit-transition-delay: 999999s;
  }

  &-sm {
    height: unset;
  }
}

// =========== Badge ===========

.ant-badge {
  color: currentColor;

  &-multiple-words {
    padding: 0px 2px;
  }
}

// ============ Dropdown ========
.ant-select-dropdown {
  border: 1px solid @line-faint-light;
  border-radius: 3px;

  .select-dropdown-content {
    display: flex;
    flex-direction: column;

    .ant-checkbox-wrapper {
      margin: auto 0;
    }

    .ant-input {
      width: unset;
    }
  }

  .ant-select-item-option {
    padding-top: 2px;
    padding-bottom: 2px;

    .ant-select-item-option-content {
      border-radius: 3px;
      padding: 2px 8px 2px 4px;
      margin-left: 4px;

      &:hover {
        background: @gray-200;
      }
    }

    &:hover,
    &:focus {
      background: inherit;
    }

    &.ant-select-item-option-selected {
      background: inherit;

      .ant-select-item-option-content {
        background: white;
        background: @gray-200;
        font-weight: 400;
      }
    }
  }

  .select-dropdown-input {
    border-radius: 5px;
    margin: 6px 12px 8px;
    min-width: unset;
    width: unset;
  }
}

// ============ Dropdown Scrollbar ========
.rc-virtual-list-scrollbar-thumb {
  background: @line-faint-light !important;
  border-radius: 2px !important;
  width: 2px !important;
  left: unset !important;
  right: 2px !important;
}

// ======= Popover & Tooltip ========
.ant-popover-inner {
  border-radius: 6px;
  .shadow();
}

.ant-popover-arrow {
  z-index: 2;
}

.ant-popover-inner-content {
  padding: 8px;
}

// ======= Spinner ========

.ant-spin-text {
  color: @complement-light !important;
}

// ======== Popconfirm ========

.ant-popconfirm {
  .ant-popover-buttons {
    display: flex;
    justify-content: right;
  }

  .ant-popover-inner {
    padding: 2px 8px;
  }
}

// ======== Message =========

.ant-message {
  &-notice-content {
    border-radius: 5px;
  }

  .anticon-check-circle {
    svg {
      color: #43A047
    }
  }
}

// ======== Date Picker =======

.ant-picker {
  &-small {
    height: 24px !important;
  }

  &-suffix {
    svg {
      height: 14px;
      width: 12px;
    }
  }
}

// ======== Number Input =======

.ant-input-number {
  &-sm {
    height: unset !important;
  }
}

// ======= Modal ===========

@modal-default-border-radius: 8px;

.ant-modal {
  &-content {
    border-radius: @modal-default-border-radius;

    .ant-modal-header {
      border-radius: @modal-default-border-radius @modal-default-border-radius 0 0;
    }
  }

  .ant-card {
    border-radius: @modal-default-border-radius;

    .ant-card-header {
      border-radius: @modal-default-border-radius @modal-default-border-radius 0 0;
    }
  }
}

//  ======= Modal background ===========
.ant-modal,
.ant-drawer {
  &-mask {
    background-color: rgba(84, 89, 106, 0.2);
  }
}


// ======= Form ===========

.form-flex {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 6px;
  }

  .ant-form-item-label {
    &>label {
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    vertical-align: unset !important;

    @media (max-width: 575px) {
      .ant-col {
        padding: 0px;
        flex: unset !important;
      }
    }

    .ant-input-number {
      width: 100%;

      &-input {
        height: 34px;
      }
    }
  }

  .form-flex-row {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;

    .ant-form-item {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      min-width: 0;

      @media (max-width: 575px) {
        min-width: 100%;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";