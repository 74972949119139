@import 'src/themes/default/default';
@import '../../../shared/styles/mixins';

.editable-table {
  .ant-table {
    .scroll()
  }

  .ant-table-content {
    overflow: auto;
  }

  .ant-empty {
    position: sticky;
    left: 0;
    overflow: hidden;
    max-width: 90vw;
  }

  .ant-form-item-explain {
    display: none;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";