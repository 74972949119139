@import '../../report-configuration.mixins.less';
@import '../../../../shared/styles/mixins.less';

.report-dd-references {

    &__attribute-label {
        // margin-left: 12px;
        display: flex;
        align-items: center;
        margin-top: -8px;
        margin-bottom: 16px;

        svg:first-child {
            margin-right: 8px;
        }

        &__label {
            font-weight: 500;
        }

        &__link {
            margin-left: 4px;
            // font-weight: 500;
        }
    }

    .ant-badge-count {
        background-color: #e74b4b
    }

    &__section-title {
        .section-title-mixin();
        &::after {
            margin-left: 0;
        }
    }

    &__buttons-bar {
        .buttons-bar-mixin();
        margin-bottom: 24px;
    }

    &__body {
        .ant-table-content {
            .scroll();
        }

        &__buttons-bar {
            .buttons-bar-mixin();
            align-items: center;

            &__carets {
                display: flex;
                flex-direction: column;
                // margin-right: 4px;
                margin-left: 3px;
    
                & > span > svg {
                    width: 11px !important;
                    height: 11px !important;
                }
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";