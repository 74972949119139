@import "src/themes/default/default";

.header-tree-mapper-parent-node {
  display: flex;
  align-items: center;

  &__children {
    border-left: 1px solid @faint-dark;
    margin-left: 4px;
    padding-left: 21px;

    &.hidden {
      display: none;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";