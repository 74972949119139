.dd-references-filter {
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 24px 16px 24px 16px;
    }

    .ant-checkbox-wrapper {
        margin: 0;
    }

    &__body {
        flex-grow: 1;
    }

    &__footer {
        display: flex;
        gap: 8px;
        justify-content: center;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";