@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.fields-with-tabs {
  width: 100%;

  .ant-tabs-nav {
    margin: 0;
    bottom: -1px;

    &::before {
      display: none;
    }

    .ant-tabs-tab {
      position: relative;
      background-color: @white;
      border-color: @white;
      border-bottom: 0;
      border-radius: 0;
      color: @text-gray;

      &:after {
        position: absolute;
        content: "";
        left: -2px;
        right: 0;
        bottom: 0.5px;
        height: 1px;
        width: calc(100% + 4px);
        background: @line-faint-light;
      }

      &:hover {
        color: @base-dark;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @text-complement-light;
          font-weight: 500;

          &:hover {
            color: @text-complement-light;
          }
        }

        &:after {
          display: none;
        }
      }
    }

    .ant-tabs-nav-list {
      :first-child {
        &:after {
          left: 2px;
          border-radius: 5px 0 0 0;
        }
      }
    }

    .ant-tabs-tab-active {
      border-color: @line-faint-light !important;
      border-bottom-color: @white !important;
      border-radius: 5px 5px 0 0 !important;

      .ant-tabs-tab-btn {
        color: @text-gray;
        font-weight: 500;
      }
    }
  }

  .ant-tabs-content {
    border: 1px solid @line-faint-light;
    border-radius: 5px;
    padding: 16px 16px 0 16px;

    .ant-form-item-control-input {
      min-height: auto;
    }
  }

  .ant-tabs-top {
    .ant-tabs-nav {
      .ant-tabs-tab {
        .ant-tabs-tab-btn {

          &:hover,
          &:focus {
            color: @base-dark;
          }
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: @base-dark;
            font-weight: 500;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .first-is-active {
    .ant-tabs-content {
      border-radius: 0 5px 5px 5px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";