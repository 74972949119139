@import '../../report-configuration.mixins.less';
@import '../../../../shared/styles/mixins.less';
@import '/src/themes/default/default';

.report-table-settings {
  &__buttons-bar {
    .buttons-bar-mixin();
    margin-bottom: 24px;
  }

  &__functional-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__section-title {
    .section-title-mixin();

    &::after {
      margin-left: 0;
    }
  }

  &__body {
    &__section {
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
      }
    }

    &__section:not(.main-columns-section) {
      padding-top: 24px;
      border-top: 1px solid @line-faint-light;
      margin-top: 24px;
    }
  }


  .form-list {
    .form-list-mixin();

    &.hidden {
      display: none;
    }
  }

  .button-only-icon {
    &.ant-btn {
      padding: 0 !important;
      min-width: 38px;

      & svg {
        height: 14px;
        width: 14px;
        margin-left: 0 !important;
      }
    }
  }

  &__controls {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    flex-wrap: wrap;

    &__select {
      width: fit-content;
      min-width: 180px;
    }
  }
}

.security-settings-modal,
.format-settings-modal-opener {
  &__form {
    display: flex;
    gap: 16px;
    // flex-wrap: wrap;

    .ant-form-item {
      display: block;
      margin-bottom: 0;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";