@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.doc-journal-pie-chart-widget {
  border-radius: 10px;
  max-height: 540px;

  overflow: auto;
  .scroll();

  .ant-card-body {
    padding: 16px;
  }
}

.doc-journal-pie-chart-widget__title {
  margin-bottom: 0 !important;
}

.doc-journal-pie-chart-widget__select {
  min-width: 40px;

  .ant-select-selector {
    padding-left: 0 !important;
  }
}

.doc-journal-pie-chart-widget__chart {
  margin: 0 auto;
}

.doc-journal-pie-chart-widget__chart-label {
  font-weight: bold;
  fill: @widget-color-blue;
}

.doc-journal-pie-chart-widget__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";