@import "src/themes/default/default";

.editable-field {
  width: auto;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &_disabled {
    .editable-field__icon {
      cursor: default;

      &:hover {
        border-color: unset;
        color: @default-gray;
      }
    }
  }

  &__icon {
    color: @default-gray;
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid @default-gray;

    &:hover {
      color: @black;
      border-color: @black;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";