.jasper-report {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  .ant-card-body {
    padding: 0;
  }

  &-spinner {
    text-align: center;
    margin-top: 40px;
  }

  &-header {
    flex: 0 0 auto;
    margin-bottom: 15px;
  }

  &-frame {
    width: 100%;
    height: 80vh;
    border: none;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";