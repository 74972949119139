@import "src/themes/default/default";
@import "shared/styles/mixins";

.request-table-widget__title {
  margin-bottom: 0 !important;
}

.request-table-widget__select {
  min-width: 40px;

  .ant-select-selector {
    padding-left: 0 !important;
  }
}

.request-table-widget__chart {
  margin: 0 auto;
}

.request-table-widget__chart-label {
  font-weight: bold;
  fill: @widget-color-blue;
}

.request-table-widget__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
}

.request-table-widget {
  border-radius: 10px;
  max-height: 540px;
  .period-table(@max-height: 400px)
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";