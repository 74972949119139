@import "src/themes/default/default";
@import "shared/styles/mixins.less";

.download-report-modal {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .alert {
    .badge {
      cursor: pointer;
      font-size: 24px;
      color: @link-complement-light;
      height: 35px;

      &-animate {
        animation: .7s badge-animate 6 linear alternate;
      }

      >.ant-badge-count {
        background-color: @error;
      }

      sup {
        position: absolute;
        top: -1px;
        right: -8px;
        transform-origin: 100% 0;
      }

      svg {
        width: 35px;
        height: 35px;
        color: @icon-dark;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .modal {
    .set-flex();

    .table {
      margin: 10px 0;
    }
  }

  .button-only-icon {
    &.ant-btn-icon-only {
      padding: 0 !important;
      min-width: 36px;
      border: none;
      box-shadow: none;

      &>svg {
        margin-left: 0 !important;
      }
    }
  }
}

.action-buttons {
  margin: 0 0 16px 0;
  max-width: 600px;
  .set-columns(@width: 30%);
}

.link {
  margin: 5px 0;
  color: @text-complement-light;
  .set-font(@size: 14px, @height: 18.2px);
  cursor: pointer;
}

.inactive {
  pointer-events: none;
}

.status {
  cursor: pointer;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 5px;

  >svg {
    width: 20px;
    height: 20px;
  }

  &.success {
    >svg {
      stroke: @green-600;
    }

    border-color: @green-600;
  }

  &.warning {
    >svg {
      stroke: none;
    }

    border-color: @warning;
  }

  &.error {
    >svg {
      stroke: @red-600;
    }

    border-color: @red-600;
  }

  &.onHold {
    svg {
      stroke: @blue-600;
    }

    border-color: @blue-600;
  }

  &.reject {
    svg {
      fill: @red-600;
    }

    border-color: @red-600;
  }

  &.pending {
    svg {
      fill: @blue-600;
    }

    border-color: @blue-600;
  }

  &.running {
    fill: @gray-600;
  }
}

@keyframes badge-animate {
  to {
    transform: translate(0, -30%);
    font-size: 24px;
    color: @link-complement-light;
    cursor: pointer;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";