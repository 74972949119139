@import '../../report-configuration.mixins.less';
@import 'src/themes/default/default';

.report-configurator {
  &_hidden {
    display: none;
  }

  &__buttons-bar {
    .buttons-bar-mixin();
    margin-bottom: 24px;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__title {
      .section-title-mixin();
      font-size: 14px;
      font-weight: 600;
      margin: 16px 0;
    }

    &__body {
      .form-flex-mixin();

      &__row {
        &__extra {
          font-size: 12px;
          color: @error
        }

        .with-tooltip {
          .ant-form-item-control {
            display: flex;
            flex-direction: row;
            height: 32px;

            .ant-form-item-extra {
              margin: auto;

              .tooltip-field {
                display: flex;
                margin-top: 2px;
              }
            }
          }
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";