@import "src/themes/default/default";

// ========== SVG =======

.set-svg-color(@color) {
  svg {
    fill: @color;
  }

  svg.stroke-change-color {
    fill: none;
    stroke: @color;
  }

  svg.path-stroke-change-color {
    fill: none;

    path {
      stroke: @color;
    }
  }
}

// ========== Buttons primary =======
.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 2px;
  height: 36px;
  padding: 9px 14px;
  color: white;
  background-color: @complement-light;
  border: 1.5px solid @faint-light;
  box-shadow: 0 1px 2px @gray-shadow;
  border-radius: 5px;
  transition: color;

  &>div {
    display: flex;
    align-items: center;
  }

  >svg:first-child:not(:last-child),
  >span:first-child:not(:last-child),
  >.action-icon:first-child:not(:last-child) {
    margin-left: -2px;
  }

  >svg+span,
  >.anticon+span,
  .action-icon+span {
    margin-left: 6px !important;
  }

  &:last-child {
    text-align: center;
  }

  &:active,
  &:focus {
    color: @base-light;
    background-color: @complement-light;
    .set-svg-color(@base-light);
  }

  &:hover {
    background: @base-light;
    color: @complement-light;
    border: 1.5px solid @complement-light;

    .set-svg-color(@complement-light);
  }

  &:disabled {
    opacity: 0.5;
    color: @base-light;
    background-color: @complement-light;

    .set-svg-color(@base-light);
  }

  &:disabled:active,
  &:disabled:hover {
    color: @base-light;
    background-color: @complement-light;

    .set-svg-color(@base-light);
  }
}

.button-no-hover_focus {

  &:hover,
  &:focus {
    background: @complement-light;
    color: @base-light;
    border-color: @faint-light;
    box-shadow: none;
  }
}

.button-filter-default {
  padding: 9px 14px;
  gap: 8px;
  height: 36px;
  background: @base-light;
  border: 1.5px solid @default-gray;
  box-shadow: 0 1px 2px rgba(226, 230, 234, 0.8);
  border-radius: 5px;
  font-style: normal;
  font-size: 14px;
  line-height: 18.2px;
  text-align: center;
  color: @default-gray;
}

// ========== Buttons critic =======

.button-critic {
  .button-primary();
  border: 1.5px solid @critic-dark;
  color: @critic-dark;
  background: @base-light;
  .set-svg-color(@critic-dark);

  &:hover,
  &:focus {
    background: @critic-dark;
    color: @base-light;
    border-color: @critic-dark;

    .set-svg-color(@base-light);
  }

  &:disabled {
    opacity: 0.8;
    border: 1.5px solid @critic-light;
    color: @critic-light;
    background: @base-light;
    cursor: not-allowed;
    .set-svg-color(@critic-light);

    &:active,
    &:hover {
      opacity: 0.8;
      color: @critic-light;
      background: @base-light;
      cursor: not-allowed;
      .set-svg-color(@critic-light);
    }
  }
}

// ========== Buttons default =======

.button-default {
  .button-primary();
  background: @base-light;
  color: @complement-light;
  border: 1.5px solid @complement-light;
  box-shadow: 0 1px 2px rgba(226, 230, 234, 0.8);
  .set-svg-color(@complement-light);
}

.button-default-hover {

  &:active,
  &:focus,
  &:hover {
    background-color: @complement-light;
    border: 1.5px solid @faint-light;
    color: @base-light;
    box-shadow: 0 1px 2px @gray-shadow;

    .set-svg-color(@base-light);
  }

  &:disabled {
    opacity: 0.5;
    color: @default-gray;
    background: @base-light;

    .set-svg-color(@default-gray);
  }

  &:disabled:active,
  &:disabled:hover {
    color: @default-gray;
    background: @base-light;

    .set-svg-color(@default-gray);
  }
}

// ========== Input =======
.input {
  width: 100%;
  height: 36px;
  border-radius: 3px;
  border: 1px solid @line-faint-light;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  align-items: center;
  box-shadow: none;

  &::-webkit-input-placeholder {
    .input-placeholder();
  }

  &::-moz-placeholder {
    .input-placeholder();
  }

  &::placeholder {
    .input-placeholder();
  }

  &:hover,
  &:focus {
    border: 1px solid @input-focused-light; //@blue-600
    box-shadow: none;
  }
}

.input-placeholder {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: @placeholder-gray;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
}

.input-disabled {
  border: 1px solid @line-faint-light;
  background: @gray-100;
  color: @text-gray-200;

  &:hover,
  &:focus {
    border-color: @line-faint-light;
    background: @gray-100;
  }
}

.hide-extra-border() {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.ant-form-item-has-error {

  .ant-input {
    border: 1px solid @red-600;
  }

  &:hover {
    background: transparent;
  }

  .ant-input:focus,
  .ant-input:hover,
  .ant-input-password:focus,
  .ant-input-password:hover {
    border-color: @red-600;
    box-shadow: none;
    background-color: transparent;
  }

  .select-field {
    border-color: @red-600 !important;

    .caret-icon {
      color: @red-600;
    }
  }
}

//========== Custom scrollbar =======
.scroll {
  // Custom scrollbar for Firefox
  scrollbar-color: @faint-light @gray-200;
  scrollbar-width: thin;

  // Custom scrollbar for Chrome and Safari
  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 9999px;
    background: @gray-200;
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    //добавить если нужен scroll не на всю высоту/ширину
    //margin: 20px 0;
  }

  &::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: @faint-light;
  }
}

.scroll_thin(@track-color, @thumb-color) {
  // Custom scrollbar for Firefox
  scrollbar-color: @thumb-color @track-color;
  scrollbar-width: thin;

  // Custom scrollbar for Chrome and Safari
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 9999px;
    background: @track-color;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    //добавить если нужен scroll не на всю высоту/ширину
    //margin: 20px 0;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: @thumb-color;
  }
}

//================ Modal header ========
.modal-header {
  .ant-modal-header {
    margin: 0 30px;
    padding: 0;
  }

  .ant-modal-title {
    font-weight: 600;
    font-size: 16px;
    padding: 30px 0 16px;
  }

  .ant-modal-close-x {
    position: relative;
  }

  .ant-modal-body {
    padding: 16px 30px 30px;

  }
}

// ====== Common box shadow ========
.shadow {
  filter: drop-shadow(0px 0px 8px rgba(42, 43, 55, 0.15))
}

// ====== Default card head with line ========
.card_head(@mt: 32px) {
  margin-top: @mt;
  border-bottom: none;
  min-height: auto;

  .ant-card-head-title {
    display: flex;
    font-size: 24px;
    font-weight: 400;
    padding: 0;
    line-height: 28px;
    color: @text-accent-dark;

    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: @line-faint-light;
      margin: auto 0 auto 16px;
    }
  }
}

.set-font(@size, @height: 20px, @weight: 400, @style: normal, @align: left) {
  font-size: @size;
  font-style: @style;
  font-weight: @weight;
  line-height: @height;
  text-align: @align;
}

.set-flex(@display: flex, @direction: column, @justify: space-between, @align: center) {
  display: @display;
  flex-direction: @direction;
  justify-content: @justify;
  align-items: @align;
}

.set-columns (@width, @display: flex, @wrap: nowrap, @justify: space-between, @margin: 16px) {
  display: @display;
  flex-wrap: @wrap;
  justify-content: @justify;

  >* {
    flex-basis: @width;
    margin-right: @margin;
  }
}

.set-width(@width) {
  width: @width;
}



// ====== Auth ========
@fields-vertical-gap: 25px;

.auth-form {
  width: 600px;
  background-color: @accent-dark;
  border: 1px solid @faint-dark;
  border-radius: 8px;
  padding: 45px 0 90px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    color: @base-light;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0;
  }

  .ant-btn-primary {
    background-color: @base-light;
    box-shadow: none;
    border-color: @faint-light;
    color: @complement-light;

    &:hover {
      border-color: @base-light;
      background-color: @accent-dark;
      color: @base-light;
    }
  }

  .ant-form-item-control-input {
    margin-bottom: 2px;
    margin-top: 2px;
  }

  // get rid of autofill styles
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
  }
}

.auth-fields {
  padding-top: 45px;
  width: 375px;

  .ant-form-item {
    margin-bottom: @fields-vertical-gap !important;

    .ant-form-item-explain {
      display: none;
    }

    .ant-input-affix-wrapper {
      padding: 0;
      background-color: transparent;
      color: @white !important;

      .ant-input-suffix,
      .ant-input-prefix {
        height: 100%;
      }

      .ant-input-suffix {
        padding-right: 10px;

        .anticon {
          color: @white;
        }
      }

      .ant-input-prefix {
        height: 100%;
        background-color: @white;
        width: 65px;
        display: flex;
        justify-content: center;
        margin: 0;
        border-bottom-left-radius: 1px;
        border-top-left-radius: 1px;

        svg {
          color: @icon-dark;
        }
      }

      .ant-input,
      input::placeholder {
        color: @white
      }

      .ant-input {
        padding-left: 25px;
      }
    }
  }
}

.auth-tooltip {

  .ant-tooltip-arrow-content,
  .ant-tooltip-inner {
    background-color: @white;

  }

  .ant-tooltip-inner {
    color: @critic-dark;
  }
}

.auth-error {
  margin-bottom: @fields-vertical-gap;
  background-color: @critic-bg-dark;
  color: @white;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 5px;
  border: 1px solid @faint-dark;

  .anticon {
    padding-right: 6px;
  }
}

// ====== Period Date in Dashboard and Widget ========
.period-table(@max-height) {
  .ant-table-wrapper {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: @background-faint-light;
  }

  .ant-table {
    max-height: @max-height;
    border: 0;
    font-size: 15px;
    color: @gray-900;
    scrollbar-color: unset;
    background: white;
    font-weight: 500;
  }

  .ant-card-body {
    padding: 16px;

  }

  .ant-table-thead>tr>th {
    background: white;
    border-bottom: 0;
    font-size: 15px;
    color: @gray-900;
    font-weight: 500;
    white-space: nowrap;

    &:last-child {
      display: block !important;
    }
  }

  .ant-table-body {
    border-radius: 10px;
    overflow-y: scroll;
    border: 1px solid @gray-800;
    max-height: @max-height;
    background-color: @background-faint-light;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @gray-300;
      border-radius: 25px;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 20px;
      margin: 10px;
    }
  }

  .ant-table-tbody>tr>td {
    background-color: @background-faint-light;
    border-bottom: 0;
    font-weight: 400;
    padding: 0 16px;

    &:first-child {
      font-size: 14px;
      color: @gray-800;
      font-weight: 400;

      .title {
        padding: 9px 26px;
      }

      .parent {
        &:first-child {
          padding: 10px 0;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}