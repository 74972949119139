@import 'src/themes/default/default';
@import '../widgets.less';

.progress-list-wrapper {
  &_open {
    width: 498px;
  }
}

.progress-list-anchor {
  position: relative;
  height: 204px;
}


.progress-list-statistics {
  .widget-mixin();
  font-size: 14px;
  height: 204px;
  width: 498px;
  display: flex;
  flex-direction: column;

  &.display-as-one-column {
    width: 242px;
  }

  &_open {
    height: unset;
    max-height: 466px;
    position: absolute;
    z-index: 1;
  }

  &__up-bar {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &__title {
      font-weight: 400;
      font-size: 14px;
      color: @faint-dark;
    }

    &__bar {
      height: 1px;
      flex-grow: 1;
      margin: 0 16px;
      background-color: @line-faint-light;
    }

    &__plus {
      font-size: 16px;
      color: @link-complement-light;
      cursor: pointer;

      .ant-icon {
        overflow: visible;
      }
    }
  }

  &__body {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 18px);
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px 0;
    flex-grow: 1;

    scrollbar-width: thin;
    scrollbar-color: @gray-400 @white;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @gray-400;
      border-radius: 20px;
      border: transparent;
    }

    &.display-as-one-column {
      grid-template-columns: 1fr;

      .progress-list-statistics__body__item {
        grid-template-columns: auto max-content 15px;
      }
    }

    &_open {
      overflow: auto;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";