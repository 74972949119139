@import 'src/themes/default/default';

.table-report-attachment-upload-frame {
  &__title {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;

    svg {
      color: currentColor;
    }
  }

  &__modal {
    .ant-modal-close {
      cursor: default;
    }

    .ant-modal-close:focus,
    .ant-modal-close:hover {
      color: unset !important;
    }
  }

  &__controls {
    display: flex;
    align-items: baseline;
    gap: 12px;
    margin-left: -30px;

    &_close {
      svg {
        margin-bottom: -4px;
        color: #959595;

        &:hover {
          color: rgba(20, 20, 20, 0.75)
        }
      }

      &_not-loaded {
        svg {
          color: @gray-300 !important;
          cursor: default;
        }
      }
    }
  }

  &__wrap {
    .ant-modal {
      top: 78px;
      right: 15px;
    }

    @border-radius: 10px;

    .ant-modal-content,
    .ant-modal-header {
      border-radius: @border-radius;
    }

    &_open {
      .ant-modal-header {
        border-radius: @border-radius @border-radius 0 0 !important;
      }
    }
  }

  .ant-progress-status-success {
    .ant-progress-bg {
      background-color: #43A047;
    }

    .ant-progress-text {
      color: #43A047;
    }
  }
}

.table-report-attachment-upload-progress-item {
  margin-bottom: 8px;

  &__title {
    display: flex;
    gap: 8px;
    align-items: center;

    &__icon {
      cursor: pointer;
      color: @faint-dark
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";