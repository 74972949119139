.ais-main-page {
    display: grid;
    gap: 12px;
    min-height: 100vh;

    grid-template-rows: auto auto 120px auto;
    grid-template-columns: minmax(703px, 1fr) 1fr 1fr;
    grid-template-areas:
      "register request-statistics availability-status"
      "register request-statistics token-status"
      "request-statuses request-statistics unavailability-status"
      "request-statuses operations unavailability-status"
    ;

    @media screen and (max-width: 1800px) {
        grid-template-rows: auto 190px auto auto auto auto;
        grid-template-columns: minmax(751px, 1fr) 1fr;
        grid-template-areas:
          "register request-statistics"
          "request-statuses request-statistics"
          "request-statuses operations"
          "availability-status availability-status"
          "token-status token-status"
          "unavailability-status unavailability-status"
        ;
      }

    @media screen and (max-width: 1365px) {
        grid-template-rows: repeat(7, auto);
        grid-template-columns: 1fr;
        grid-template-areas:
          "register"
          "request-statistics"
          "request-statuses"
          "operations"
          "availability-status"
          "token-status"
          "unavailability-status"
        ;
    }

    &__register {
        grid-area: register;
    }

    &__request-statuses {
        grid-area: request-statuses;
    }

    &__request-statistics {
        grid-area: request-statistics;
    }

    &__operations {
        grid-area: operations;
    }

    &__availability-status {
        grid-area: availability-status;
    }

    &__token-status {
        grid-area: token-status;
    }

    &__unavailability-status {
        grid-area: unavailability-status;
    }

    &__column {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";