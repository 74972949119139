@import "src/themes/default/default";


.documents-upload-stage {
  display: flex;
  width: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  &:not(:last-child)::after {
    background: #4a4a4a;
    height: 1px;
    content: "";
    flex-grow: 1;
  }

  .stage-status-icon {
    cursor: pointer;
    height: 25px;
    width: 25px;
    border: 2px solid @gray-icon;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
      fill: @gray-icon;
    }

    .initial {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    &.success {
      svg {
        stroke: @green-600;
        fill: none;
      }

      border-color: @green-600;
    }

    &.reject {
      svg {
        width: 12px;
        fill: @red-600;
      }

      border-color: @red-600;
    }

    &.pending {
      svg {
        fill: @blue-600;
      }

      border-color: @blue-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";