@import '/src/themes/default/default';

.ais-token-status-dashboard {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 16px;

    &-icon {
      height: 20px;
      width: 20px;
      border-radius: 100px;

      background: @default-gray;

      &.active {
        background: @dashboards-active;
      }

      &.inactive {
        background: @dashboards-inactive;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";