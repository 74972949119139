@import "src/themes/default/default";

.message-reply-author {
  display: flex;
  width: fit-content;
  gap: 0 16px;

  font-size: 14px;
  color: @black-0;

  .author {
    display: flex;
    gap: 0 6px;
  }

  .name {
    position: relative;

    &::after {
      content: '';
      display: flex;
      width: 1px;
      height: 20px;
      background: @background-gray-100;
      position: absolute;
      top: 0;
      right: -8px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";