@import "src/themes/default/default";

.list__item {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;

  &__label {
    color: @text-gray-400;
    margin-right: 12px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";