@import "src/themes/default/default";

.empty-widget-greeting {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__partner-logo {
    width: @widgets-placeholder-partner-logo-width;
    height: @widgets-placeholder-partner-logo-height;
  }

  &-logo {
    &__icon {
      width: 117px;
      height: 139px;

      path {
        stroke: @accent-dark;
      }
    }

    &__name {
      font-size: 30px;
      letter-spacing: 2px;
      color: @accent-dark;
      margin-left: 60px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";