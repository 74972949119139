@import 'src/themes/default/default';

.table-report-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  border: 1px solid @line-faint-light;
  border-radius: 5px;
  background-color: @gray-100;

  &__wrapper {
    margin-bottom: 28px;
    background-color: white;

    &_sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &__buttons {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__controls {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .border-left {
    border-left: 1px solid @line-faint-light;
  }

  .pl-16 {
    padding-left: 16px;
  }

  .pr-16 {
    padding-right: 16px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";