@import '../../shared/styles/mixins.less';

.section-title-mixin() {
    display: flex;
    font-size: 24px;
    font-weight: 400;
    padding: 0;
    line-height: 28px;
    color: #000000;
    margin: 0 0 26px 0;

    &::after {
        content: '';
        display: inline-block;
        flex-grow: 1;
        height: 1px;
        background-color: #E0E4F0;
        margin: auto 0 auto 16px;
    }
}

.buttons-bar-mixin() {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top: 8px;
    display: inline-flex;
    background: white;
    z-index: 10;

    button.ant-btn > .anticon > svg {
        height: 18px;
        width: 18px;
    }
}

.form-align-items-mixin {
    .form-list__items {
        display: flex;
        align-items: baseline !important;
    }
}

.form-flex-mixin() {
    .ant-row.ant-form-item {
        @media (max-width: 575px) {
            .ant-col {
                flex: unset !important;
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin: 0 0 16px 0px;

        .ant-row.ant-form-item {
            margin-bottom: 0;
        }

        &_grow {
            .ant-row.ant-form-item {
                flex-grow: 1;
            }
        }

        &_align-end {
            align-items: end;
        }

        &_align-start {
            align-items: flex-start;
        }

        .ant-checkbox-wrapper {
            margin: 0;
            & > span {
                white-space: normal;
            }
        }
    }
}

.form-list-mixin() {
    .scroll();
    overflow: auto;
    padding-bottom: 16px;
    margin-top: 16px;

    &__items {
        display: flex;
        column-gap: 16px;
        align-items: baseline;

        .ant-input-number {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        & > .ant-row.ant-form-item {
            flex-shrink: 0;
            margin-bottom: 0 !important;
        }

        button {
            align-self: flex-end;
        }
    }
}

.drawer-filter-mixin() {
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 24px 16px 24px 16px;
    }

    .ant-checkbox-wrapper {
        margin: 0;
    }

    &__body {
        flex-grow: 1;
    }

    &__footer {
        display: flex;
        gap: 8px;
        justify-content: center;
    }
}

.conditions-modal-mixin() {
    &__buttons {
        margin-bottom: 20px;
        display: flex;
        gap: 8px;
    }

    .ant-form-item-label > label::after {
        display: none;
    }

    &__inputs-row {
        display: flex;
        gap: 8px;
        margin-bottom: 18px;

        .ant-form-item {
            flex-shrink: 0;
            display: block;
            margin-bottom: 0;
        }
    }

    &__form {
        @row-line-bottom-margin: 20px;

        overflow: auto;
        .scroll();

        &__condition-row {
            display: flex;
            margin-top: 16px;
            gap: 8px;

            &:before {
                content: '';
                align-self: flex-end;
                margin-bottom: @row-line-bottom-margin;
                width: 40px;
                height: 1px;
                background-color: @blue-800;
                align-items: center;
            }

            .ant-form-item {
                flex-shrink: 0;
                display: block;
                margin-bottom: 0;
            }
        }

        &__row {
            display: flex;

            &__controls {
                display: flex;
                gap: 8px;
                margin-right: 16px;
                align-items: center;
            }

            &:not(:last-child) {
                margin-bottom: 18px;
            }

            &__bottom {
                @top-margin: 0px;

                position: relative;
                display: flex;
                flex-direction: row;
                margin-top: @top-margin;
                margin-left: 37px;

                &__conditions {
                    &_hidden {
                        display: none;
                    }
                }

                &__line {
                    margin-top: -14px - @top-margin;
                    margin-left: 3px;
                    margin-bottom: @row-line-bottom-margin;
                    width: 1px;
                    background-color: @blue-800;
                }
            }

            &__top {
                display: flex;
                gap: 8px;

                .ant-form-item {
                    flex-shrink: 0;
                    display: block;
                    margin-bottom: 0;

                    .ant-badge-count-sm {
                        font-size: 10px;
                    }

                    .ant-badge-multiple-words {
                        padding: 0px 2px;
                    }
                }
            }
        }
    }
}