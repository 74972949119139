@import 'src/themes/default/default';

.upload-dropzone {
  .ant-upload {
    width: 100%;

    &-list {
      display: none;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 8px;

  &__zone {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background-color: @gray-200;

    &:hover {
      background-color: @gray-100;
    }


    &__icon {
      svg {
        height: 36px;
        width: 36px;
        color: @blue-600;
      }
    }

    &__tip {
      margin-top: 2px;
      font-weight: 400;
    }
  }

  &__tip {
    font-size: 12px;
  }

  &__file-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";