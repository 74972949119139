@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.field-block {

  border: 0;

  .ant-card-head {
    .card_head();
    margin-top: 0;
    padding-left: 0;
    margin-bottom: 24px;

    .ant-card-head-title {
      font-size: 20px;
    }
  }

  .ant-card-body {
    padding: 0;
    display: flex;
    gap: 10px 16px;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: none;
    }

    .ant-row.ant-form-item {
      //flex-grow: 1;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";