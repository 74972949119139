@import "../../../../shared/styles/mixins";
@import "src/themes/default/default";

.date-picker {
  .input();

  min-width: unset;

  &::placeholder {
    color: @faint-dark;
  }

  .ant-picker-input>input::placeholder {
    color: @faint-dark;
  }

  .ant-picker-input>input:hover,
  .ant-picker-input>input:focus {
    border: none;
    box-shadow: none;
  }

  &.ant-picker-disabled {
    &:hover {
      border: 1px solid @line-faint-light;
    }
  }

  .ant-picker-clear {
    right: -2px;

    .clear-icon {
      fill: @faint-dark;
    }
  }
}

.alert {
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  color: @red-600;
}

.error {
  border: 1px solid @red-600;

  &:hover {
    border: 1px solid @red-600;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";