.control-ratio-settings-rule {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  padding: 20px 20px 0 20px;

  &__buttons {
    display: flex;
    gap: 20px;
  }

  .section-switcher {
    width: 527px;
  }
}

.cr-rules-table-wrapper {
  min-height: 20px;
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";