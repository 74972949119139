@import "src/themes/default/default";
@import "shared/styles/mixins";

.settings-programs {
  display: flex;
  flex-direction: column;

  &__card {
    border-radius: 10px;

    &>.ant-card-head {
      .card_head();
    }

    &>.ant-card-body {
      padding-top: 32px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";