@import "src/themes/default/default";
@import "../../../../../../shared/styles/mixins";

.table-report-check-header {
  background-color: @white;
  margin-bottom: 24px;

  .selected-values {
    width: 292px;
    border-right: 1px solid @line-faint-light;
    padding: 20px;

    .wrapper {
      padding: 10px 0;
      .set-flex(@align: flex-start, @justify: flex-start);
    }

    .text {
      .set-font(@size: 16px, @height: 24px, @weight: 600);
    }
  }

  .filters {
    .set-flex(@align: flex-start, @justify: flex-start);
    width: 100%;
    padding: 16px 24px;
    border: 1px solid @line-faint-light;
    border-radius: 5px;

    .title {
      .set-flex(@direction: row);
      width: 100%;


      .text {
        .set-font(20px, 28px);
        white-space: nowrap;
        margin-right: 24px;
      }

      .divider {
        min-width: initial;
      }
    }

    .buttons {
      .set-flex(@direction: row, @justify: flex-start);
    }
  }


  .button {
    &_active {
      .button-primary();
      margin-right: 10px;
    }

    &_cancel {
      .button-default();
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";