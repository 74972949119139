@import 'src/themes/default/default';

.counter {
  display: inline-flex;
  margin: 20px;
  padding: 2px 8px;
  align-items: center;
  border-radius: 3px;
}

.counter.gray {
  color: @white;
  background-color: @primary-gray;
}

.counter.blue {
  color: @white;
  background-color: @link-complement-light;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";