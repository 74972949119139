@import 'src/themes/default/default';

.comparison-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid @line-faint-light;
  padding: 16px;
  margin-top: 8px;
  background-color: @gray-100;

  &.hidden {
    display: none;
  }

  &__form {
    display: flex;
    gap: 16px;
    width: 100%;
    flex-wrap: wrap;

    .ant-form-item {
      flex-grow: 1;
      flex-basis: 0;
      min-width: 0;
      flex-direction: column;
      margin-bottom: 0;

      .ant-form-item-label {
        display: flex;
      }
    }
  }

  &__submit-btn {
    margin-top: 32px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";