@import "src/themes/default/default";

.dashboard {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  background-color: @gray-100;

  border: 1px solid @line-faint-light;
  border-radius: 5px;

  padding: 16px;
  margin-bottom: 24px;

  .widget-wrapper {
    //flex-grow: 1;
  }


  &__skeleton {
    max-width: 450px;

    &.half_circle_statistics {
      max-width: 176px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";