@import "src/shared/styles/mixins";
@import "src/themes/default/default";

.list-array-factory {
  padding-bottom: 16px;

  .ant-card {
    padding: 0;

    .ant-card-head {
      .card_head();

      .ant-card-head-title {
        font-size: 14px;
        font-weight: 500;
      }

      margin: 0 0 16px;
    }

    .ant-card-body {
      padding: 0;
      min-height: unset;
    }

    .ant-list-items {
      display: flex;
      flex-direction: column;
      gap: 12px 0;

      margin-bottom: 16px;
    }

    .ant-list-item {
      padding: 0;
      border: 0;
    }

    .ant-list-item-meta {
      margin-bottom: 0;

      &-content {
        display: flex;
        gap: 0 8px;
        align-items: center;
      }

      &-title {
        color: @faint-dark;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }

      &-description {
        flex-grow: 1;
        color: @black-0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .buttons {
    &.with-bottom-margin {
      margin-bottom: 16px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";