@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

.set-color-picker-hover-border(@color) {
  .ql-picker-item[data-value='@{color}'] {
    &:hover {
      border: 1px solid @color;
    }

    &.ql-selected {
      border: 1px solid @color;
    }
  }
}

.editor-toolbar {
  .ql-picker-options {

    .ql-picker-item[data-value="#FFFFFF"] {
      width: 29px !important;
      height: 29px !important;
      border: 1px solid @line-faint-light;
    }

    .set-color-picker-hover-border(#000000);
    .set-color-picker-hover-border(#333333);
    .set-color-picker-hover-border(@faint-dark);
    .set-color-picker-hover-border(#1054B8);
    .set-color-picker-hover-border(#6BAAFF);
    .set-color-picker-hover-border(#ED1C24);
    .set-color-picker-hover-border(#43A047);
    .set-color-picker-hover-border(#43A047);
    .set-color-picker-hover-border(#FAA03F);
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";