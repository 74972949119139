@import 'src/themes/default/default';
@import 'src/shared/styles/mixins.less';

.columns-setting-modal {

  .ant-modal-header,
  .ant-modal-body {
    padding: 30px 30px 16px 30px;
  }

  &__content {
    border: 1px solid @line-faint-light;
    border-radius: 5px;

    .columns-list {
      &.overflow {
        .ant-list-items {
          overflow: auto;
          height: 440px;
          .scroll()
        }
      }

      &__row {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        border-bottom: 1px solid @line-faint-light;
        padding-left: 10px !important;

        &.checked {
          background-color: @gray-100;

          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        .ant-checkbox {
          .ant-checkbox-inner {
            width: 14px;
            height: 14px;
          }

          +span {
            padding-left: 20px;
            padding-right: 0;
          }
        }

        &.check-all {
          font-size: 16px;
          color: @faint-dark;
          margin: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .ant-checkbox-wrapper {
          margin: 0;
        }
      }
    }

    .ant-list-item {
      padding: 0;
    }
  }

  &__actions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;

    .simple-action-btn {
      width: 452px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";