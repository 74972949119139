@import 'src/components/personal-account/personal-account.mixins.less';

.dashboards {
  &__buttons {
    .buttons();
    margin-bottom: 48px;
  }

  .ant-table-cell {
    min-width: 300px;
  }

  .ant-select-selector{
    min-width: 200px;
  }

  .ant-table-container {
    display: inline-block;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";