@import '../../../../../report-configuration.mixins.less';

.extra-attributes-modal-opener-content {
    &__form {
        .form-flex-mixin();

        &__attributes {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            align-items: flex-end;

            & .ant-form-item {
                margin-bottom: 0px;
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";