@import "src/themes/default/default";

.app-notification {
  display: flex;
  align-items: center;
  gap: 10px;

  width: unset;
  max-width: 400px;

  padding: 16px;
  background: @white;
  box-shadow: 0 0 4px rgba(42, 43, 55, 0.1);
  border-radius: 3px;

  .ant-notification-notice-icon {
    margin-left: 0;
    top: 13px;
  }

  .ant-notification-notice-message {
    font-weight: 700;
    font-size: 12px;
    line-height: 14.06px;
    white-space: pre-wrap;
    margin-left: 32px;
    padding-right: 0;
  }

  .ant-notification-notice-description {
    margin-left: 32px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-wrap;
    letter-spacing: .3px;
  }

  .ant-notification-notice-close {
    display: contents;
  }

  .ant-notification-close-x {
    margin-top: -15px;
    margin-right: -5px;
  }

  .ant-notification-notice-close span svg {
    width: 12px !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 7px;
    border-radius: 3px 0 0 3px;
  }

  &.notification-error {
    &::before {
      background: @red-600;
    }
  }

  &.notification-success {
    &::before {
      background-color: @green-600;
    }
  }

  .button-link {
    margin: 0;
    padding: 0;
    color: @link-complement-light;
    text-align: left;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
}

div.delete-rows-notification {
  white-space: pre-wrap;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";