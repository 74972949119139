@import 'components/report-configuration/report-configuration.mixins.less';
@import 'src/themes/default/default';

.request-form-container {
  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 4px 0 24px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--grey_stroke, #E0E4F0);
  }
}

.replies-card {
  .ant-card-body {
    padding: 0;
  }
}

.section {
  &__title {
    .section-title-mixin();
    font-size: 18px;
    font-weight: 400;
    margin: 16px 0;
  }

  &__link {
    padding: 0;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-align: inherit;
    line-height: inherit;
    cursor: pointer;
    color: @link-complement-light;

    &:hover {
      text-decoration: underline;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";