@import '../../../../report-configuration.mixins.less';

.dd-references-edit-modal-form {    
    .form-flex-mixin();

    .ant-row.ant-form-item {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__attributes-block {
        @pad: 16px;
        padding: @pad;
        border: 1px solid #E0E4F0;
        border-radius: 5px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &__attributes {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(3, 1fr);
            align-items: flex-end;

            & .ant-form-item {
                margin-bottom: 0px;
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";