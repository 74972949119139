@import "src/themes/default/default";

.form-modal {
  .ant-card-head {
    margin: 0 30px;
    padding: 0;
    border-color: @line-faint-light;
  }

  .ant-card-head-title {
    font-weight: 600;
    font-size: 16px;
    padding: 30px 0 16px;
  }

  .ant-modal-close-x {
    position: relative;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-card-body {
    padding: 16px 30px 30px;
  }
}

.confirm-error-message {
  .ant-modal-body {
    padding-bottom: 30px;

    .ant-card-head {
      padding: 0 24px;
    }

    .ant-card-body {
      padding: 0 !important;
      margin: 16px 30px 0 !important;
    }
  }
}

.form-warning {
  &__buttons {
    display: flex;
    gap: 16px;
    width: 100%;

    .ant-btn {
      width: 100%;
    }
  }
}

.form-warning {
  &__buttons {
    display: flex;
    gap: 16px;
    width: 100%;

    .ant-btn {
      width: 100%;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";