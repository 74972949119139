.draggable-frame {
    position: inherit;

    &__wrap {
        position: inherit;
        pointer-events: none;
        .ant-modal {
            position: absolute;
            cursor: default;
            z-index: 1000;

            &-header {
                cursor: move;
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";