@import "src/themes/default/default";
@import "../../../../../shared/styles/mixins";

.time-picker-with-label {
  display: flex;
  position: relative;
  justify-content: space-between;
  .input();

  &.disabled {
    .input-disabled();
  }

  .time-picker-label {
    margin-left: 10px;
    color: @text-gray;

    &.disabled {
      color: @default-gray;
    }
  }

  .time-picker {
    border: 0;
    background-color: transparent;
    padding-left: 7px;

    &:hover {
      border: 0;
      background-color: transparent;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";