@import 'src/themes/default/default';

.dd-modal {
  &__name {
    color: @default-gray;
  }

  &__list {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
  }

  &__item {
    cursor: pointer;
    color: @link-complement-light;

    &:hover {
      text-decoration: underline;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";