.format-settings-content{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .ant-form-item{
    width: 49%;
    display: flex;
    flex-direction: column;
  }

  &__square-button {
    & > svg {
      margin-left: 0;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";