.status {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 12px 8px 12px 0;
    border: none;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";