@import 'src/themes/default/default';

.vertical-bar-chart {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;

  &__body {
    min-height: 170px;
    display: flex;
    gap: 6px;
  }

  &__footer {
    font-size: 12px;
  }

  &__entry {
    width: 8px;
    background-color: @gray-200;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;


    &__value {
      background-color: #FFE068;
      border-radius: 10px;
      transition: height .5s ease-in-out;

      &:not(&_is-zero) {
        &::before {
          display: block;
          content: '';
          width: 8px;
          height: 8px;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          background: white;
          margin-top: -8px;
        }
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";