@import 'src/themes/default/default';

.upload-scan-files-item {
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid @line-faint-light;

  &__description {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 5px;

    &__download {
      cursor: pointer;
      color: @faint-dark;

      &:hover {
        color: @gray-600;
      }
    }

    &__delete {
      cursor: pointer;
      font-size: 12px;
      color: @red-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";