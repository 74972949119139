@import 'src/themes/default/default';
@import '../../../shared/styles/mixins';

.request-form-container {
  .ant-card-head {
    .ant-card-head-wrapper {
      .card_head(0);
    }

    border-bottom: none;
  }

  min-height: 100vh;

  .ant-card {
    .ant-card-body {
      padding: 20px;
    }

    .ant-card-head {
      min-width: 83.375rem;
    }
  }

  .field-block {
    .ant-card-body {
      padding: 0;

      .ant-form-item {
        flex-grow: 1;
      }
    }
  }

  .content {
    .ant-card-head {
      margin-top: 24px;
    }

    .ant-card-head-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 140% */
    }
  }

  .form {
    .ant-form-item {
      height: unset;
    }
  }

  .request-form-container__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 4px 0 24px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--grey_stroke, #E0E4F0);
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";