.simple-action-btn {
  &.no-padding {
    padding: 0;
  }
  &.icon-no-margin {
    svg {
      margin: 0;
    }
  }
  &.icon-16 {
    .anticon {
      width: 16px;
      height: 16px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";