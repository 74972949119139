@import "src/themes/default/default";

.header-preview {
  &__actions {
    display: flex;
    gap: 15px;
    padding-bottom: 20px;
  }

  &__description {
    color: @black;
    font-size: 16px;

    &.tree-description {
      padding: 20px 0 10px 0;
    }
  }

  &__table {
    padding-top: 10px;

    .ant-table-tbody {
      display: none;
    }

    .ant-table .ant-table-thead th {
      padding: 6px 11px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-cell {
      padding: 10px 5px;
      white-space: nowrap;
    }
  }

  &__empty {
    padding-bottom: 15px;

    .ant-empty-footer {
      display: flex;
      justify-content: center;
    }
  }

  .ant-table {
    border-color: @gray-600;
    border-bottom: 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";