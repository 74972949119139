@import 'shared/styles/mixins.less';

.report-download-settings {

  .button-save {
    width: 100%;
  }

  .row {
    .set-flex(@direction: row);
    flex-wrap: nowrap;
    > :not(:last-child) {
      margin-right: 16px;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";