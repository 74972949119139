.ui-blocker {
    position: relative;
    cursor: default;

    &__backdrop {
        z-index: 9;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    &__children {
        &_with-opacity {
            opacity: 0.2;
        }
    }

    &__content {
        z-index: 10;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);

        &__verbose-error {
            font-size: 12px;
            text-align: center;
            margin: 6px 0;
            max-height: 60px;
            max-width: 35vw;
            overflow: auto;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";