@import "src/themes/default/default";
@import "../../../../shared/styles/mixins.less";


.fields-tree {
  display: flex;
  flex-direction: column;
  border: 1px solid @line-faint-light;
  border-radius: 5px;
  margin: 25px 0 0 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @gray-100;
    border-bottom: 1px solid @line-faint-light;
    color: @faint-dark;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 10px;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 16px;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex-basis: 50%;
      gap: 20px;
    }
  }
}

.button {
  color: @link-complement-light;
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 18.2px;
  text-align: center;
  font-weight: 500;

  &__icon {
    color: @link-complement-light;
    font-size: 16px;
  }

  &:hover {
    background-color: transparent;
    border: 1px solid;
    border-radius: 3px;
    transition: 0.3s ease-in-out;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";