@import "src/themes/default/default";

.documents-upload-modal {
  .ant-modal-content {
    width: 700px;
  }

  .ant-modal-wrap {
    position: inherit;
  }

  .ant-modal-mask {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .close-button {
    margin-left: auto;
  }

  .stage-modal-title-icon {
    stroke: @black-0;

    &.arrow-icon {
      position: absolute;
      left: -10px;
      top: 10px;
      transition: transform 0.3s linear;
      width: 30px;
      height: 30px;
    }
  }

  &.hidden-content {
    .ant-modal-body {
      display: none;
    }
  }

  .documents-upload-title {
    width: 100%;
    cursor: move;
  }

  .documents-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .document {
      display: flex;
      margin: 10px;
      justify-content: space-between;
    }
  }

  .document-icon {
    display: flex;
    align-items: center;

    .document-name {
      padding-top: 2px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg:not(.file-icon) {
      fill: @gray-icon;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";