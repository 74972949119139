@import 'src/themes/default/default';
@import '../widgets.less';

.bar-statistics {
  .widget-mixin();
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 204px;
  min-width: 345px;

  &__up-bar {
    margin: 0 0 8px 0;
    display: flex;
    align-items: baseline;

    &__total {
      font-weight: 700;
      font-size: 40px;
      color: @link-complement-light;
    }

    &__label {
      color: @faint-dark;
      margin-left: 7px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__bar-wrapper {
    display: flex;
    margin-bottom: 16px;

    &__bar {
      &:last-child {
        margin-right: 0;
      }

      height: 8px;
      border-radius: 1px;
      margin-right: 4px;
    }
  }

  &__description {
    font-size: 14px;
    flex-grow: 1;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, auto);
    width: fit-content;

    &__block {
      display: flex;
      margin-right: 32px;
      margin-bottom: 8px;
      align-items: center;

      &_no-wrap {
        white-space: nowrap;
        overflow: hidden;
      }

      &__border {
        height: 100%;
        width: 4px;
        flex-shrink: 0;
        border-radius: 1px;
        margin-right: 8px;
      }

      &__key {
        color: @faint-dark;

        &_no-wrap {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__value {
        margin-left: 8px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";