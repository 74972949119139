@import "../../../shared/styles/mixins";

.table-container {
  flex-grow: 2;

  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .table-action {
      padding: 0;
      margin-bottom: 24px;

      .ant-form-item {
        margin-bottom: 10px;
      }
    }

    .search {
      margin-right: 25px;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 15px;
    margin-bottom: 24px;
    background-color: @gray-100;
    border: 1px solid @line-faint-light;
    border-radius: 5px;
    min-height: 68px;

    .search {
      min-width: 100px;
      max-width: 469px;
      flex-grow: 1;
      margin-right: 17px;
    }

    .subsections {
      margin-right: 0;
      margin-left: 16px;
    }

    .reference-switcher {
      margin-left: 0;
    }
  }

  @media (max-width: 1400px) {
    &__actions {
      flex-wrap: wrap;
    }
  }
}


.settings-user-settings-mailing {
  background: @white;
  border: none;
  padding: 0;
  margin: 0 0 24px 0;

  .ant-space-item {

    .save-action-btn {
      .button-primary();
    }

    .delete-action-btn {
      .button-critic();
    }

    .button-back {
      padding: 10px 14px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: center;

      svg {
        margin-top: 0;
        width: 16px;
        height: 16px;
      }

      &:hover {
        svg {
          fill: @white;
        }
      }
    }
  }

}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";