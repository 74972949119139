@import 'src/themes/default/default';

.custom-radio {
  height: 36px;
  display: flex;
  align-items: center;
  border: 1px solid @line-faint-light;
  border-radius: 5px;

  &.ant-radio-group-small {
    height: 26px;

    .ant-radio-button-wrapper {
      &:not(&:last-child)::after {
        margin-bottom: -1px;
        height: 16px;
      }
    }
  }

  .ant-radio-button-wrapper {
    height: 100%;
    color: @faint-dark;
    padding: 0 10px;
    border: none;
    border-radius: 5px;

    span {
      opacity: 0.7;
    }

    span:nth-child(2) {
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    &:first-child {
      border-right: none;
      padding-right: 6px;
    }

    &:last-child {
      padding-left: 6px;
    }

    &:not(&:last-child)::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: -1px;
      transform: translateY(-25% - 10px);
      height: 20px;
      width: 1px;
      background: @faint-dark;
      opacity: 0.7;
      z-index: 2;
    }

    &::before {
      content: none;
    }
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    background-color: #F8FAFD !important;
  }

  .ant-radio-group-outline,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:hover {
    border: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }

  .ant-radio-button-wrapper-checked {
    border: none;
    font-weight: 500;
    color: @complement-dark;

    span {
      opacity: 1;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";