@import 'src/themes/default/default';

.request-content-view-modal {
  &__link {
    color: @complement-light;
    cursor: pointer;
    text-align: center;

    &:hover {
      opacity: .9;
    }
  }

  &__content {
    white-space: pre-wrap;
    padding: 16px;
    background: rgba(@accent-dark, 0.03);
    border-radius: 10px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";