.ais-availability-status-dashboard {
    &__body {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 16px;

        &-icon-wrapper {
            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";