@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

.custom-card {
  border-radius: 10px;

  &__extra {
    margin-bottom: 24px;
    max-height: 1000px;
    transition: max-height .55s ease-in-out;
    overflow: hidden;

    &_transition-1 {
      max-height: 2000px;
      transition: max-height .7s ease-in-out;
    }

    &_hidden {
      max-height: 0px;
      overflow: hidden;
    }
  }

  &__title {
    display: flex;
    gap: 14px;

    &_with-extra-content {
      cursor: pointer;
    }

    &__accordion-icon {
      color: @accent-dark;
      transition: all 250ms ease-in-out;
      display: flex;
      align-items: center;

      &_opened {
        transform: rotate(180deg);
        transition: all 250ms ease-in-out;
      }

      &:hover {
        color: @complement-light;
      }
    }
  }

  .ant-card-head {
    margin-top: 32px;
    border-bottom: none;
    min-height: auto;

    &-title {
      display: flex;
      font-size: 24px;
      font-weight: 400;
      padding: 0;
      line-height: 28px;
      white-space: unset;
      color: @text-accent-dark;

      &:after {
        content: '';
        display: inline-block;
        flex-grow: 1;
        flex-basis: 30%;
        height: 1px;
        background-color: @line-faint-light;
        margin: auto 0 auto 16px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";