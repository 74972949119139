@import "src/shared/styles/mixins.less";
@import "src/themes/default/default";

.forbidden-page {
  .forbidden-form {
    .auth-form();
    padding-bottom: 65px;
    width: 500px;
    z-index: 2;

    &__header {
      display: flex;
      flex-direction: row;
      width: 375px;
      padding-bottom: 25px;

      h1 {
        font-size: 18px;
        text-align: left;
        margin-left: 10px;
        align-self: center;
      }
    }

    &__button-container {
      margin: 0;
      display: flex;
      flex-direction: column;
      width: 375px;
      align-items: center;

      button {
        margin-bottom: 25px;
      }
    }

    &__verbose-error {
      margin: 0;
      display: flex;
      flex-direction: row;
      padding-bottom: 25px;
      font-size: 16px;
      color: @error;

      &__logo {
        object-fit: contain;
        height: 100%;
        margin-right: 6px;
      }
    }

    &__partner-logo {
      width: @forbidden-partner-logo-width;
      height: @forbidden-partner-logo-height;
      margin-right: 60px;
    }
  }
}

.forbidden-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__button-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 6px;
  }

  &__verbose-error {
    font-size: 12px;
    text-align: center;
    margin: 6px 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";