@import "../../../../shared/styles/mixins";

.input-tooltip{
  display: flex;
  gap: 0 8px;
}
.tooltip-list{
  height: auto;
  margin: auto;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";