@import "src/themes/default/default";

.list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(398px, 1fr));
  grid-gap: 24px;

  &__item {
    position: relative;
    margin: 0;
    padding: 0 24px 24px 0;

    // vertical line
    &:after {
      content: '';
      position: absolute;
      background-color: @line-faint-light;
      width: 1px;
      height: calc(100% - 24px);
      right: 0;
      top: 0;
    }

    // horizontal line
    &:before {
      content: '';
      position: absolute;
      background-color: @line-faint-light;
      height: 1px;
      width: calc(100% - 24px);
      left: 0;
      bottom: 0;
    }

    &:nth-child(even) {
      padding-right: 0;

      &::after {
        display: none;
      }

      &:before {
        width: 100%;
      }
    }

    &:nth-last-child(2) {
      &::before {
        display: none;
      }

      padding-bottom: 0;
    }

    &:last-child {
      &::before {
        display: none;
      }

      padding-bottom: 0;
    }
  }

  &__item-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 12px;
  }

  &.list-with-even-items {
    .list-grid__item {
      &:nth-last-child(2) {
        &::before {
          display: block;
        }
      }
    }
  }

  &.list-with-one-item {
    display: block;

    .list-grid__item {
      &:after {
        display: none;
      }
    }
  }

  &.list-empty {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";