@import 'src/themes/default/default';

.header-tree-mapper-row {
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 5px 5px 5px 0;

  &__wrapper {
    display: flex;
    justify-content: flex-start;
  }

  &__icon {
    padding: 5px 5px 5px 0;

    svg {
      fill: @faint-dark;
      width: 12px;
      height: 5px;

      &.closed {
        transform: rotate(-90deg);
      }
    }
  }

  &-title {
    display: flex;
    width: 50%;
    padding-right: 15px;
  }

  &-attribute {
    width: 50%;
  }

  .ant-form-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 0;

    .ant-form-item-explain {
      display: none;
    }
  }

  &__message {
    padding-top: 5px;
    font-size: 13px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";