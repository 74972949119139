@import '../../../../report-configuration.mixins.less';
@import 'src/themes/default/default';

.dd-parameters-edit-modal-form {
  .form-flex-mixin();

  .ant-row.ant-form-item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .dd-parameters-rule {
    &__block {
      &__title {
        align-self: center;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";