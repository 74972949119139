@import 'src/shared/styles/mixins';
@import 'src/themes/default/default';

.ais-documents-register {
  &__filters {
    width: 100%;
  }

  &-table {
    &__link-column {
      .ant-table-filter-column-title {
        display: flex;
        justify-content: center;
      }
    }

    &__pud-button {
      color: @complement-light;
      cursor: pointer;

      &:not(&_disabled) {
        &:hover {
          opacity: .9;
        }
      }

      &_disabled {
        color: @gray-500;
        cursor: default
      }
    }

    .ant-table-placeholder {
      height: 110px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";