@import "src/themes/default/default";

.stage-modal {
  right: 220px;
  top: 60%;
  bottom: 100px;
  position: absolute;

  .anticon .anticon-right-circle svg {
    width: 30px;
  }
}

.success {
  height: 25px;
  width: 25px;
  border: 1px solid green;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg {
    width: 12px;
  }
}

.icon {
  height: 25px;
  width: 25px;
  border: 1px solid gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .svg {
    width: 12px;
  }
}

.document-list {
  display: flex;
}

.document-type-file {
  width: 30px;
  margin-left: 5px;
}

.log {
  display: flex;
  align-items: center;
}

.select-modal {
  width: 250px;
}

.outlinedIcons {
  margin-right: 10px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";