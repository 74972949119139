@import 'src/themes/default/default';

.crypto-pro-signature-modal-content {
  &__overlapping {
    border: 1px solid @line-faint-light;
    padding: 16px;
    border-radius: 3px;
    font-size: 12px;
    background-color: @gray-100;

    &__warning {
      margin-bottom: 10px;
      display: flex;
      gap: 5px;
      align-items: center;
      color: #f25a5f;
      font-weight: 500;

      svg {
        margin-bottom: 1px;
        font-size: 14px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";