.parameters-filter {
    &__title {
        font-size: 20px;
        font-weight: 400;
        color: rgba(20, 20, 20, 0.85);
    }

    &__filters {
        display: inline-flex;
        column-gap: 16px;
        row-gap: 14px;
        margin-bottom: 0;
        flex-wrap: wrap;
        align-items: self-end;

        // [data-type="date"] {
            // max-width: 150px;
        // }

        .ant-form-item {
            margin-bottom: 0;
            vertical-align: unset !important;
            flex-basis: 15%;
            flex-grow: 1;
            flex-shrink: 0;
            min-width: 200px;
            max-width: calc(15% + 16px); // flex-basis + column-gap
        
            @media (max-width: 575px) {

                .ant-form-item-label > label {
                    padding-bottom: 8px;
                }

                min-width: 100%;
                .ant-col {
                    padding: 0px;
                    flex: unset !important;
                }
            }
        
            .ant-input-number {
              width: 100%;
              &-input {
                height: 34px;
              }
            }
          }
    }

    &__buttons {
        display: flex;
        gap: 8px;
        margin-top: 24px;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";