@import "../../../shared/styles/mixins";

.directory-tabs {
  .directory-form-tab-content {
    &.ant-tabs-tabpane {;
      padding: 0 !important;
    }

    .directory-item > .ant-card {
      border: 0;

      .ant-card-body {
        padding: 20px 16px;
      }
    }

    .directory-item {
      overflow: auto;
      .scroll();
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";