@import "src/themes/default/default";

.download-file-link {
  cursor: pointer;
  color: @text-complement-light;

  &-icon {
    color: @default-gray;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";