@import 'src/themes/default/default';

.crypto-pro-signature {
  border: 1px solid #E0E4F0;
  border-radius: 5px;
  padding: 16px;

  &__status {
    margin-left: 8px;
    color: @red-600;

    &_is-valid {
      color: @green-600;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";