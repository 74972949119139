@import 'src/themes/default/default';

.custom-table,
.table-report {
  .ant-table {
    &-filter-column {
      margin: -16px -2px;

      &-title {
        >div {
          display: flex;
          align-items: center;
        }
      }
    }

    &-filter-trigger-container {
      pointer-events: all !important;

      &:hover,
      &-open {
        background: #dfe3ec !important;
      }
    }

    &-column-has-sorters {
      pointer-events: none;

      &:hover {
        background: #f8fafd !important;
      }
    }

    &-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
      background-color: transparent;
    }
  }
}

.table-column-menu {
  @filter-padding: 0px;

  width: 280px;
  border: 2px solid #E0E4F0;
  border-radius: 5px;
  margin-top: 8px;
  padding: @filter-padding;
  position: relative;
  background-color: #F8FAFD;

  & .ant-select-selection-placeholder {
    color: @faint-dark;
  }

  &__triangle {
    position: absolute;
    @w: 8px;
    width: 0;
    height: 0;
    border-left: @w solid transparent;
    border-right: @w solid transparent;
    border-bottom: @w solid #E0E4F0;
    margin-top: -9px - @filter-padding;
  }

  &__body {
    color: @faint-dark;

    &__search {
      padding: 10px;
      border-bottom: 1px solid #E0E4F0;
      margin-bottom: 0;

      & .ant-input-affix-wrapper-disabled {
        background-color: hsl(216, 16%, 97%) !important;
      }
    }

    &__filter {
      display: flex;
      flex-direction: column;
      padding: 8px 10px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      &_open {
        background-color: white;

        &:not(:nth-child(2)) {
          border-top: 1px solid #E0E4F0;
        }

        &:not(:nth-last-child(2)) {
          border-bottom: 1px solid #E0E4F0;
        }

        &>.table-column-menu__body__filter__header {
          margin-bottom: 10px;
          // color: @link-complement-light;
        }
      }

      &_applied {
        &>.table-column-menu__body__filter__header {
          color: @link-complement-light;
        }
      }

      &__header {
        &:hover {
          color: #1054B8;

          &>.table-column-menu__body__filter__header svg {
            color: red;
          }
        }

        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
      }

      &__body {
        &_closed {
          display: none;
        }

        &>input {
          height: 32px;
        }

        &>div {
          width: 100%;
        }

        &>div,
        &>input {
          margin-bottom: 8px;
        }

        &__buttons {
          display: flex;
          margin-top: 12px;

          &>button {
            width: 50%;

            &:first-child {
              margin-right: 4px;
            }
          }
        }
      }

      &__icon {
        width: 20px;
        margin-right: 8px;
      }
    }

    &__sort-option,
    &__hide-option {
      @w: 5px;

      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      user-select: none;
      min-height: 44px;

      &:hover {
        color: #1054B8;

        & .table-column-menu__body__sort-option__triangle {

          &_desc,
          &_asc {
            border-top-color: #1054B8 !important;
            border-bottom-color: #1054B8 !important;
          }
        }
      }

      &_selected {
        color: @link-complement-light;

        & .table-column-menu__body__sort-option__triangle {

          &_desc,
          &_asc {
            border-top-color: @link-complement-light !important;
            border-bottom-color: @link-complement-light !important;
          }
        }
      }

      &__triangle-wrapper {
        display: flex;
        margin-right: 8px;
        min-width: 20px;
        justify-content: center;
      }

      &__triangle {
        width: 0;
        height: 0;
        border-left: @w - 1px solid transparent;
        border-right: @w - 1px solid transparent;

        &_desc {
          border-bottom: @w solid @faint-dark;
        }

        &_asc {
          border-top: @w solid @faint-dark;
        }
      }
    }

    &__icon {
      width: 20px;
      margin-right: 8px;
    }
  }

  &__footer {
    padding: 10px;
    border-top: 1px solid #E0E4F0;
    min-height: 56px;

    &__spinner {
      position: absolute;
      left: 18px;
      top: 9px
    }

    &>button {
      width: 100%;
    }
  }
}

.ant-dropdown-placement-bottom {
  @triangle-margin: 8px;
  @dropdown-margin: -5px;

  &Right {
    & .table-column-menu__triangle {
      right: @triangle-margin;
    }

    & .ant-table-filter-dropdown {
      margin-right: @dropdown-margin;
    }
  }

  &Left {
    & .table-column-menu__triangle {
      left: @triangle-margin;
    }

    & .ant-table-filter-dropdown {
      margin-left: @dropdown-margin;
    }
  }
}

.ant-table-filter-dropdown {
  border-radius: 5px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";