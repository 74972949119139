@import "src/themes/default/default";

.directories-company-form-card {
  border-radius: 8px;
  border-color: @line-faint-light;
  flex: 1;

  .ant-card-head-title {
    font-size: 20px;
    font-weight: normal;
    padding: 18px 0 !important;
  }

  .ant-row {
    column-gap: 9px;

    .ant-form-item {
      flex: 1;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";