@import "src/themes/default/default";
@import "src/shared/styles/mixins";

.fields () {
  .ant-form-item {
    margin: auto auto 25px;
    width: 375px;
    z-index: 2;

    .ant-input-affix-wrapper {
      background-color: transparent;
      padding: 0;

      .ant-input-prefix {
        height: 100%;
        background-color: @base-light;
        width: 65px;
        justify-content: center;
      }

      input {
        padding-left: 25px;
        color: @base-light;
      }

      input::placeholder {
        color: @base-light;
      }
    }

    &-control {
      &-input {
        &-content {
          .try-again-button {
            color: @base-light;
            text-align: left;
            padding: 0;

            span {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &__error {
    width: 375px;
    margin: auto;
    .auth-error();
  }
}

.login-page {
  .two-fa-form {
    .auth-form();
    padding-bottom: 65px;
    display: flex;
    flex-direction: column;

    &__container {
      &__header {
        margin: auto;

        .description {
          padding: 45px 0 45px;
          display: flex;
          flex-direction: row;

          h2 {
            color: @base-light;
            margin-right: 10px;
            font-size: 16px;
          }

          p {
            color: @base-light;
            margin: auto auto;
          }
        }

        h1 {
          color: @base-light;
        }
      }

      .connect-form__fields {
        .fields();
      }

      .qrcode-form__fields {
        .fields();

        &__description {
          h2 {
            font-size: 14px;
          }

          p {
            margin-left: 0;
            width: 450px;
          }

          p:last-child {
            text-align: center;
            width: 480px;
            margin: 35px 0 0 -70px;
          }
        }
      }

      .message-sent-form__fields {
        .fields();
        align-items: center;
        width: 550px;
      }

      &__qr {
        margin: auto 0 auto 60px;
      }
    }
  }

  .connect-form {
    width: 700px;

    &__header {
      width: 600px;

      &__description {
        width: 573px;

        p {
          margin-left: 0;
          width: 454px;
        }
      }
    }
  }

  .message-sent-form {
    width: 700px;

    &__header {
      &__description {
        p {
          font-size: 16px;
          text-align: center;
          margin-left: 0;
          width: 465px;
        }
      }
    }
  }

  .qrcode-form {
    width: 1076px;
    height: 500px;

    &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 500px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";