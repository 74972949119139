@import "src/themes/default/default";

.sing-and-file-list-uploader {
  display: flex;
  width: 100%;
  gap: 0 9px;
  margin: 16px 0;
  padding-right: 16px;

  .signs,
  .files {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.button-download-document-list {
  margin: 16px 0;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";