@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.input-password {
  .input();

  &.ant-input-affix-wrapper-disabled {
    .input-disabled();

    .ant-input {
      transition: none;

      &:hover,
      &:focus {
        .hide-extra-border();
      }
    }
  }

  box-shadow: none !important;

  .ant-input {
    background: transparent;
  }
}

// === Hide inside border because of mixin ======
.form .input-password {

  .ant-input {
    .hide-extra-border();

    &:hover,
    &:focus,
    &:active {
      border: 0;
    }
  }

  .ant-input.ant-input-disabled {
    .hide-extra-border();

    &:hover {
      border: 1px solid @line-faint-light;
      .hide-extra-border();
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";