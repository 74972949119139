.click-confirmation {

    .ant-modal {
        &-content {padding: 0 26px;}
        &-close {margin-top: 2px;}
        &-body {padding: 18px 0 28px;}
        &-header {padding: 20px 0;}
    }

    &__label {
        margin-bottom: 14px;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 8px;

        button {
            flex-grow: 1;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";