@import 'src/shared/styles/mixins.less';
@import 'src/themes/default/default';

.row-formats-modal-content,
.security-settings-modal {
  &__buttons {
    margin-bottom: 20px;
    display: flex;
    gap: 8px;
  }

  &__form {
    @row-line-bottom-margin: 20px;

    overflow: auto;
    .scroll();

    &__condition-row {
      display: flex;
      margin-top: 16px;
      gap: 8px;

      &:before {
        content: '';
        align-self: flex-end;
        margin-bottom: @row-line-bottom-margin;
        width: 40px;
        height: 1px;
        background-color: @blue-800;
        align-items: center;
      }

      .ant-form-item {
        flex-shrink: 0;
        display: block;
        margin-bottom: 0;
      }
    }

    &__row {
      display: flex;

      &__controls {
        display: flex;
        gap: 8px;
        margin-right: 16px;
        align-items: center;
      }

      &:not(:last-child) {
        margin-bottom: 18px;
      }

      &__bottom {
        @top-margin: 0px;

        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: @top-margin;
        margin-left: 37px;

        &__conditions {
          &_hidden {
            display: none;
          }
        }

        &__line {
          margin-top: -14px - @top-margin;
          margin-left: 3px;
          margin-bottom: @row-line-bottom-margin;
          width: 1px;
          background-color: @blue-800;
        }

        .ant-form-item-label>label::after {
          display: flex;
          grid-gap: 8px;
          gap: 8px;
          content: none;
        }
      }

      &__top {
        display: flex;
        gap: 8px;

        .ant-form-item {
          flex-shrink: 0;
          display: block;
          margin-bottom: 0;

          .ant-badge-count-sm {
            font-size: 10px;
          }

          .ant-badge-multiple-words {
            padding: 0 2px;
          }
        }

        .ant-form-item-label>label::after {
          display: flex;
          grid-gap: 8px;
          gap: 8px;
          content: none;
        }

      }
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";