@import "src/themes/default/default";

.excel-header-structure-loader-modal {
  &__description {
    padding: 20px 0;
    color: @black;
    font-size: 16px;
  }

  .pt-20 {
    padding-top: 20px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";