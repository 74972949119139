@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.quill {
  display: flex;
  flex-direction: column-reverse;

  .ql-container {
    border: 1px solid @line-faint-light;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    min-height: 200px;

    &::after {
      content: '';
      width: calc(100% - 16px);
      height: 1px;

      position: absolute;
      left: 8px;
      bottom: 0;

      background-color: @line-faint-light;
    }

    .ql-editor {
      font-family: Roboto, sans-serif;
      line-height: 18px;
      font-size: 14px;
      font-style: normal;
      padding: 8px 10px;
      color: @text-gray;

      &::before {
        .input-placeholder();
        left: 10px;
      }
    }

    &.ql-disabled {
      background: @gray-100;
      border-radius: 3px;
      border-bottom: 1px solid @line-faint-light;
      min-height: unset;

      &::after {
        display: none;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";