.inline-fields {
  display: flex;
  gap: 0 8px;

  .inline-field {
    margin-bottom: 0;

    & .ant-form-item-control {
      display: inline-flex;
    }

    & .ant-form-item-label {
      padding: 0;
      margin-right: 4px;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";