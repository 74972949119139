@import "src/themes/default/default";
@import 'src/shared/styles/mixins';

.organization-structure {
  .buttons-bar {
    display: flex;
    column-gap: 12px;
    margin-bottom: 24px;
  }

  .tab-bordered {
    border: 1px solid @line-faint-light;
    border-radius: 8px;
    padding: 22px 24px;
  }

  .os-form-list {
    .scroll();
    max-height: 900px;
    overflow-y: auto;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    padding-right: 3px; // scrollbar padding
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";