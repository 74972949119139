@import 'src/themes/default/default';

.upload-scan-files-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #B7C1D4;
  cursor: pointer;
  margin-top: 2px;

  &__empty {
    cursor: default !important;
    color: #B7C1D4 !important;
  }

  &:hover {
    color: @black;
  }

  &__icon {
    color: @gray-icon;
    fill: @gray-icon;
    margin-right: 8px;

    &:hover {
      color: @file-inactive;
      fill: @file-inactive;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";