@import 'src/themes/default/default';
@import '../../shared/styles/mixins';

.table-container,
.directory-container {
  .ant-card-head {
    .card_head();
  }

  &__text {
    overflow: hidden;
    font-size: 14px;
    line-height: 128%;
    /* 17.96px */

    &.ellipsis {
      max-height: 65px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &.accented {
      font-weight: 700;
    }
  }

  &__skeleton {
    min-height: 28px;
    display: flex;
    align-items: center;

    &__paragraph {
      margin-bottom: 0;
    }
  }

  .spin {
    height: 36px;
    padding-top: 7px;
    margin-right: auto;
    margin-left: 20px;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
    margin-bottom: 24px;
    background-color: @gray-100;
    border: 1px solid @line-faint-light;
    border-radius: 5px;

    .search {
      margin-left: auto;
    }

    .subsections {
      margin-right: 0;
      margin-left: 16px;
    }

    .settings-user-settings-roles-and-organizations {
      background: @white;
      border: none;
      padding: 0;
      margin: 0 0 24px 0;

      .subsections {
        width: 153px;
        margin-top: 0;
        margin-left: -16px;
      }
    }
  }

  .ant-table-cell {
    padding: 12px 10px;
  }

  .ant-table-content {
    .scroll();
  }
}

.ant-table {
  border: 1px solid @line-faint-light;
  border-radius: 5px;

  .cell {
    &-text {
      &_bold {
        font-weight: 600;
      }
    }
  }

  .test {
    font-weight: 400;
  }

  .ant-table-selection-col {
    width: 20px;
  }

  .ant-table-selection-column {
    min-width: 34px;
    padding: 0;

    .ant-checkbox-wrapper {
      margin: 0;
    }
  }

  .ant-table-selection {
    .ant-checkbox-wrapper {
      margin: 0;
    }
  }


  .ant-table-thead {
    & tr {
      height: 44px;
      white-space: nowrap;
      padding: 13px 10px;
    }

    & th {
      font-size: 16px;
      color: @faint-dark;
      background-color: @gray-100;
      padding: 5px 10px;
      white-space: pre-wrap;
      font-weight: 400;

      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        margin-bottom: 2px;
        color: @faint-dark;

        &.active {
          color: @complement-light;
        }
      }

      &>div {
        padding: 0;
      }

      &:first-child:not(.no-radius) {
        border-top-left-radius: 5px !important;
      }

      &:last-child:not(.no-radius) {
        border-top-right-radius: 5px !important;
      }

      &.own-borders:last-child {
        border-right: 0 !important;
      }
    }
  }

  &-row-selected {
    .ant-table-cell {
      background: @gray-100 !important;
    }
  }

  &__row-link {
    cursor: pointer;
    color: @link-complement-light;
    font-weight: 400;
    line-height: 18.02px;
    font-style: normal;
  }
}

.ant-pagination {
  &-prev {
    margin-right: 0;
    border-right: 0;

    .ant-pagination-item-link {
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: @line-faint-light;
      border-radius: 3px 0 0 3px;
    }
  }

  &-next {
    .ant-pagination-item-link {
      border-width: 1px 1px 1px 0;
      border-left: 0 !important;
      border-style: solid;
      border-color: @line-faint-light;
      border-radius: 0 3px 3px 0;
    }
  }

  &-prev,
  &-next {
    .ant-pagination-item-link {
      color: @line-faint-light;
    }
  }

  &-item {
    margin-right: 0;
    border: 1px solid @line-faint-light;
    border-left: 0;
    border-radius: 0;

    a {
      color: @base-dark !important;
    }

    &-active {
      background: @complement-light;
      border-color: @faint-dark;

      a,
      &:focus a,
      &:hover a {
        color: @base-light !important;
      }
    }

    &-link {
      border: 1px solid @line-faint-light !important;

      &:hover {
        border: 1px solid @line-faint-light;
        border-left: 0;
      }
    }

    &-container {
      height: 100%;
    }

    &:first-child {
      border-left: 1px solid @line-faint-light;

      &:hover {
        border-left: 1px solid @line-faint-light;
      }
    }

    &:last-child {
      border-right: 0;

      &:hover {
        border-right: 0;
      }
    }

    &:hover,
    &:focus {
      border: 1px solid @line-faint-light;
      border-left: 0;
    }
  }

  &-jump-next,
  &-jump-prev {
    margin-right: 0;

    a {
      display: block;
      position: relative;
      margin: auto;
      height: 100%;
      border-left: 0 !important;
    }
  }

  &-options {
    display: flex;
    flex-direction: row-reverse;

    .ant-select .ant-select-selector {
      border: 1px solid @line-faint-light;
      border-radius: 3px;
    }

    &-quick-jumper {
      margin-left: 0;
      margin-right: 16px;
      display: flex;

      input[type="text"] {
        margin: 0;
        padding: 0;
        width: 40px;
        height: 32px;
        border: 1px solid @line-faint-light;
        border-radius: 3px 0 0 3px;
        text-align: center;
      }
    }
  }

  .ant-pagination-options-size-changer {
    .ant-select-selector {
      box-shadow: none !important;

      &:hover,
      &:focus {
        border: 1px solid @input-focused-light;
      }
    }

    .ant-select-suffix {
      width: 10px;
      background: center / contain no-repeat url("../../shared/assets/caret-down.svg");

      svg {
        fill: transparent;
      }
    }

    &.ant-select-open {
      .ant-select-suffix {
        transform: rotate(180deg);
      }
    }
  }

  .ant-pagination-options-quick-jumper {
    position: relative;

    input {
      background: transparent;
    }
  }

  .ant-pagination-options-quick-jumper {
    input[value=""]~span::before {
      content: "№";
      font-size: 14px;
      position: absolute;
      height: 20px;
      width: 15px;
      left: 12px;
      cursor: text;
      pointer-events: none;
      transition: color .2s linear;
    }

    input:focus~span::before {
      color: @text-gray-400;
    }
  }
}

.pagination {
  &_jumper {
    box-sizing: content-box;
    padding: 11px 13px;
    background-color: @complement-light;
    border-radius: 0 3px 3px 0;
  }
}

.edit-interface-actions {
  .search__wrapper {
    height: 100%;
    margin: 0;
  }

  .edit-action-button {
    width: 148px;
    .button-primary();
  }

  .ant-checkbox-wrapper {
    margin: 0 0 0 6px;
    white-space: nowrap;
  }

  .ant-space-align-center {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .ant-space-item:first-child {
      .ant-row {
        margin-right: 15px;
      }
    }
  }

  .table-action:not(:first-child) {
    margin-left: 0;
    margin-right: 0;
  }
}

.edit-filter-buttons {

  .save-action-btn,
  .delete-action-btn {
    width: 99px;
    margin-right: 8px;
  }

  .delete-action-btn {
    background: @critic-dark;
  }

  .ant-btn-default {
    width: 94px;
  }

  .table-action:not(:first-child) {
    margin: 0;
  }

  .ant-space-align-center {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .ant-space-item:first-child {
      .ant-row {
        margin-right: 8px;
      }
    }
  }

  .ant-space-item {
    margin-right: 8px;

    &:last-child {
      margin-right: 6px;
    }
  }

  .ant-checkbox-wrapper {
    margin: 0 5.5px 0 6px;
    white-space: nowrap;
  }

  .search {
    margin-left: 0;
  }

  .search__wrapper {
    height: 100%;
    min-width: 345px;
  }

}

.field-doc {
  white-space: pre-line;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";