@import 'src/themes/default/default';
@import '../../../../../../shared/styles/mixins.less';

.documents-download-button {
  .set-flex(@direction: row);


  >.select-field {
    .ant-select-selector {
      background-color: @complement-dark;
      border-radius: 0 5px 5px 0 !important;
      width: 100px;
      transition: all 0.5s ease-in;
      padding: 0;
      border: 1px solid @complement-dark;

      // &:hover {
      //   color: @complement-light;
      //   background-color: @complement-dark;
      //   border-color: @complement-dark;
      // }
    }

    .ant-select-selection-item {
      font-style: normal;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      color: #FFFFFF;
      margin: 0;
      border-radius: 0 3px 3px 0 !important;

      &:hover {
        color: @complement-light;
        background-color: #fff;
        border-color: @complement-light;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";