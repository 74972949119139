@import "src/themes/default/default";

.list-modal {
  font-family: Roboto, sans-serif;

  .ant-card-head {
    margin: 0 30px;
    padding: 0;
    border-color: @line-faint-light;
  }

  .ant-modal-body {
    padding: 0;

    .ant-card-body {
      padding: 18px 30px;

      .list__item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 12px;
        color: @black-0;

        &__label {
          color: @text-gray-400;
          margin-right: 12px;
        }
      }
    }
  }

  .ant-card-head-title {
    font-weight: 600;
    font-size: 16px;
    padding: 30px 0 16px;
  }

  .ant-modal-close-x {
    position: relative;
  }

  &__close-icon {
    position: absolute;
    top: 36px;
    right: 32px;
    width: 14px;
    height: 14px;
    stroke: @gray-900;
  }

  &__download-icon {
    font-size: 20px;
    color: @blue-800;
  }

  &__spinner {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";