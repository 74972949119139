@import 'src/themes/default/default';
@import '../../shared/styles/mixins';

.editable-table-modal {
  .ant-modal-body {
    padding: 30px;
  }

  .ant-table-wrapper {
    .ant-table {
      overflow: auto;
      .scroll();
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";