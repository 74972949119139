@import "src/themes/default/default";
@import "../../../../shared/styles/mixins";

.upload-field {
  width: 100%;

  .ant-input--soft-disabled {
    min-width: unset;

    .ant-input {
      min-width: unset;
    }
  }

  .ant-upload {
    width: 100%;
  }

  &__clear-icon {
    cursor: pointer;
  }

  &__btn {
    .button-primary();
  }
}

.upload-field-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";