@import 'src/themes/default/default';
@import 'src/shared/styles/mixins';

.ais-request-statuses-dashboard {
  &__table {
    min-height: 490px;
  }

  .widget-table {
    .period-table(@max-height: 450px)
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";