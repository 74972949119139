@import '../../../../report-configuration.mixins.less';

.extra-attributes-modal-opener {
    display: flex;
    justify-content: center;

    &__settings-icon {
        color: #B7C1D4;
        &:hover {
            color: @link-complement-light;
        }
    }

    &__popover {
        & > .ant-popover-content {
            margin-right: -14px;
            & > .ant-popover-arrow {
                margin-right: -14px;
            }
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";