.form-fields-responsible{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey_stroke, #E0E4F0);
  margin-bottom: 16px;

  .ant-input.ant-input-disabled {
    border: 1px solid #E0E4F0;
    background: #F8FAFD;
    color: #B7C1D5;
    border-radius: 3px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";