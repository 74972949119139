@import "../../../../shared/styles/mixins";
@import "src/themes/default/default";

.time-picker {
  .input();
  min-width: unset;

  .ant-picker-suffix {
    color: @faint-dark;
  }

  &:hover {
    .ant-picker-suffix {
      svg {
        stroke: @link-complement-light;
      }
    }
  }

  input:hover,
  input:focus {
    border: transparent;
  }

  &.ant-picker-disabled {
    &:hover {
      border: 1px solid @line-faint-light;
    }
  }

  .clear-icon {
    fill: @faint-dark;
  }
}

.time-picker-dropdown {
  .shadow();
  box-shadow: none;

  .ant-picker-time-panel-column {
    overflow-y: scroll;
    .scroll_thin(@white, @line-faint-light);
    width: 69px;

    .ant-picker-time-panel-cell {
      .ant-picker-time-panel-cell-inner {
        padding: 10px 0 10px 12px;
        height: unset;
        width: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;

        &:hover {
          background: @gray-200 !important;
        }
      }
    }

    .ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: @gray-200;
      }
    }
  }

  .ant-picker-footer {
    display: none;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";