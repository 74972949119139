.svc-main-page {

    .svc-main-page-spinner {
        width: 100%;
        height: 78vh;
        display: flex;
        flex-grow: 1;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .ant-card-bordered {
        border-radius: 10px;
    }
    .bar-statistics__description {
        grid-template-rows: repeat(2, 28px) !important;
    }

    &__widget-row {
        flex-wrap: wrap;
        row-gap: 8px;
        &:not(:first-child) {
            min-height: 220px;
            // height: 24vh;
        }

        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }

        & .widget-wrapper {
            flex-grow: 1;
        }
        &.widget-directories {
            &>div {
                width: 285px;
                height: 285px;
            }
        }
        display: flex;
        gap: 8px;
        &>div {
            width: 580px;
            flex-shrink: 0;
            flex-grow: 1;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";