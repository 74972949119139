.custom-select-spinner {
    display: flex;
    align-items: center;

    &__spin {
        margin-left: 2px;
        margin-bottom: -4px;
    }

    &__label {
        margin-left: 8px;
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";