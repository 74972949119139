.table-search {
  min-width: 100px;
  max-width: 469px;
  margin-right: 0;
  height: 36px;

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  .ant-input-prefix{
    margin-right: 0;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";