.ais-signatories {
  &-header {
    margin-bottom: 16px;
  }

  &-form-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";