@import 'src/themes/default/default';

.aside {
  display: flex;
  border: 1px solid @line-faint-light;
  border-radius: 5px;
  margin-right: 13px;

  .wrapper>.aside-row:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &.collapsed {
    display: none;
    border-right: 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";