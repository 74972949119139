@import "src/themes/default/default";

@timeline-offset: 120px;

.message-replies {
  padding: 14px;

  &.ant-timeline.ant-timeline-label {

    .ant-timeline-item-label {
      font-size: 14px;
      color: @text-gray-200;
      line-height: 20px;
      width: calc(@timeline-offset - 10px);
      text-align: left;
    }
  }

  .ant-collapse {
    .ant-collapse-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding: 0;

      .ant-collapse-arrow {
        position: relative;
        left: unset;
        top: 10px;
        margin-left: 3px;
        transition: transform 0.2s linear;
        fill: @icon-blue-200
      }
    }
  }

  &.ant-timeline.ant-timeline-label {
    .ant-timeline-item-tail {
      left: calc(@timeline-offset + 1px);
      top: -2px;
      height: 100%;
      border-left: 1px solid @background-gray-100;
    }
  }

  &.ant-timeline.ant-timeline-label {
    .ant-timeline-item-head {
      left: @timeline-offset;
      top: -4px;
      width: 13px;
      height: 13px;
      border-color: @link-complement-light;
    }
  }

  &.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(@timeline-offset - 4px);
    width: calc(100% - @timeline-offset - 20px);
  }

  .ant-timeline-item-last>.ant-timeline-item-content {
    min-height: 24px;
  }

  .reply-actions {
    .ant-btn[ant-click-animating-without-extra-node='true'] {
      &:after {
        animation: none;
      }
    }

    .ant-btn {
      width: unset;
      background: @white;
      border: none;

      color: @link-complement-light;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      box-shadow: none;

      svg {
        fill: @link-complement-light;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";