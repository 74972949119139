@import "../../shared/styles/mixins";

.form-page {
  overflow-x: auto;

  .form.ant-form > div {
    display: flex;
    flex-direction: column-reverse;
  }

  .buttons {
    padding-bottom: 16px;

    .ant-space {
      width: unset;

      .ant-space-item {
        width: unset;

        .save-action-btn, .delete-action-btn {
          .button-primary();
          width: unset;
        }
      }
    }
  }

  .ant-card {
    .ant-card-body {
      min-width: 500px;
    }
  }

  .form-placeholder {
    padding: 24px 21px;
    font-size: 16px;
    color: @black-0;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";