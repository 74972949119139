@import 'src/themes/default/default';

.table-report-scale-changer {
  &__select {
    width: 75px;

    .ant-select-selector,
    .ant-select-focused {
      height: 36px !important;
      border: 1px solid @line-faint-light !important;
      border-radius: 3px !important;
      box-shadow: none !important;
    }

    .ant-select-selection-item {
      padding-right: 0 !important;
      color: @default-gray;
      line-height: 18px;
      display: flex;
      align-items: center;
    }

    .caret-icon {
      color: @text-gray-200;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  &__option {
    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background-color: transparent;
    }

    .ant-select-item-option-content {
      display: flex;
      justify-content: center;
      padding: 0 !important;
      margin: 0 auto;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:hover {
    border-right-width: 1px !important;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";