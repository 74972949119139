@import "shared/styles/mixins";
@import "src/themes/default/default";

.modal-opener-component {
  .modal-header();

  &__close-icon {
    position: absolute;
    top: 36px;
    right: 32px;
    width: 14px;
    height: 14px;
    stroke: @gray-900;
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 8px;

    &>.ant-popover-disabled-compatible-wrapper {
      svg {
        margin: 0px;
      }
    }

    &>button {
      min-width: 160px;
      flex-grow: 1;

      svg {
        margin: 0px;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";