@import './styles/flex-fields.mixins.less';
@import 'src/themes/default/default';

.flex-fields {
  .form-flex-mixin();

  .form-flex-row {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: flex-end;

    .ant-form-item {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      min-width: 0;

      @media (max-width: 575px) {
        min-width: 100%;
      }
    }

    .invite-input {
      flex-grow: 4;
    }
  }
}

.subtitle {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: @blue-100;
    margin: auto 0 auto 16px;
  }
}

.invite-input {
  flex-grow: 2;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";