@import "src/themes/default/default";
// Цвета

.text-gray {
  &-600 {
    color: @gray-600;
  }

  &-500 {
    color: @gray-500;
  }
}

// Размер текста

.text-size-14 {
  font-size: 14px;
}

.text-size-16 {
  font-size: 16px;
}

// Вёрстка

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-inline {
  display: inline-block !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-grow-children-1 {
  >div {
    flex-grow: 1 !important;
  }
}

.flex-column {
  flex-direction: column !important;
}

.text-center {
  text-align: center !important;
}

.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}


.align-items-end {
  align-items: flex-end !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.no-select {
  user-select: none !important;
}

.on-hover-opacity {
  &:hover {
    opacity: .9;
  }
}

// ширина

.max-w {
  &-100 {
    max-width: 100%;
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

// Отступы

@spacer: 8px;

.gap {
  &-0-5 {
    gap: @spacer * 0.5;
  }

  &-0-75 {
    gap: @spacer * 0.75;
  }

  &-1 {
    gap: @spacer;
  }

  &-1-25 {
    gap: @spacer * 1.25;
  }

  &-1-5 {
    gap: @spacer * 1.5;
  }

  &-2 {
    gap: @spacer * 2;
  }

  &-3 {
    gap: @spacer * 3;
  }
}

.mt {
  &-0-5 {
    margin-top: @spacer * 0.5;
  }

  &-0-75 {
    margin-top: @spacer * 0.75;
  }

  &-1 {
    margin-top: @spacer;
  }

  &-1-5 {
    margin-top: @spacer * 1.5;
  }

  &-2 {
    margin-top: @spacer * 2;
  }

  &-2-5 {
    margin-top: @spacer * 2.5;
  }

  &-3 {
    margin-top: @spacer * 3;
  }

  &-4 {
    margin-top: @spacer * 4;
  }

  &-5 {
    margin-top: @spacer * 5;
  }
}

.mb {
  &-0-5 {
    margin-bottom: @spacer * 0.5;
  }

  &-0-75 {
    margin-bottom: @spacer * 0.75;
  }

  &-1 {
    margin-bottom: @spacer;
  }

  &-1-5 {
    margin-bottom: @spacer * 1.5;
  }

  &-2 {
    margin-bottom: @spacer * 2;
  }

  &-2-5 {
    margin-bottom: @spacer * 2.5;
  }

  &-3 {
    margin-bottom: @spacer * 3;
  }

  &-4 {
    margin-bottom: @spacer * 4;
  }

  &-5 {
    margin-bottom: @spacer * 5;
  }
}

.mr {
  &-0-5 {
    margin-right: @spacer * 0.5;
  }

  &-0-75 {
    margin-right: @spacer * 0.75;
  }

  &-1 {
    margin-right: @spacer;
  }

  &-1-5 {
    margin-right: @spacer * 1.5;
  }

  &-2 {
    margin-right: @spacer * 2;
  }

  &-2-5 {
    margin-right: @spacer * 2.5;
  }

  &-3 {
    margin-right: @spacer * 3;
  }

  &-4 {
    margin-right: @spacer * 4;
  }

  &-5 {
    margin-right: @spacer * 5;
  }
}

.ml {
  &-0-5 {
    margin-left: @spacer * 0.5;
  }

  &-0-75 {
    margin-left: @spacer * 0.75;
  }

  &-1 {
    margin-left: @spacer;
  }

  &-1-5 {
    margin-left: @spacer * 1.5;
  }

  &-2 {
    margin-left: @spacer * 2;
  }

  &-2-5 {
    margin-left: @spacer * 2.5;
  }

  &-3 {
    margin-left: @spacer * 3;
  }

  &-4 {
    margin-left: @spacer * 4;
  }

  &-5 {
    margin-left: @spacer * 5;
  }
}

.my {
  &-h {
    margin-top: @spacer * 0.5;
    margin-bottom: @spacer * 0.5;
  }

  &-1 {
    margin-top: @spacer;
    margin-bottom: @spacer;
  }

  &-1-5 {
    margin-top: @spacer * 1.5;
    margin-bottom: @spacer * 1.5;
  }

  &-2 {
    margin-top: @spacer * 2;
    margin-bottom: @spacer * 2;
  }

  &-3 {
    margin-top: @spacer * 3;
    margin-bottom: @spacer * 3;
  }

  &-4 {
    margin-top: @spacer * 4;
    margin-bottom: @spacer * 4;
  }

  &-5 {
    margin-top: @spacer * 5;
    margin-bottom: @spacer * 5;
  }
}

.fw {
  &-600 {
    font-weight: 600;
  }

  &-500 {
    font-weight: 500;
  }

  &-400 {
    font-weight: 400;
  }
}

.default-icon-color {
  color: @default-gray;

  &-fill {
    fill: @default-gray;
  }
}