@import 'src/themes/default/default';
@import "../../../../../../shared/styles/mixins";

.row-output-header {
  min-height: 80px;

  &__others {
    display: grid;
    justify-content: start;
    gap: 8px 62px;

    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    grid-auto-columns: auto;
  }

  .others-row {
    font-size: 14px;

    &__title {
      color: @faint-dark;
    }

    &__value {
      padding-left: 8px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";