@import 'src/themes/default/default';

.dd-parameters-list {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__empty {
    color: @faint-dark;
    display: flex;
    justify-content: center;
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";