.kebab-menu {
    &__button {
        display: flex;
        width: 36px;
        height: 36px;
        text-align: center;
        justify-content: center;
    }

    &__placeholder {
        background: white;
        border-radius: 5px;
        padding: 8px;
        box-shadow: 0 0 12px rgba(0,0,0,0.1);
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";