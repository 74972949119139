@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

.request-form-modal {
  .ant-card-head {
    margin: 0 30px;
    padding: 0;
    border-color: @line-faint-light;
  }

  .ant-card-head-title {
    font-weight: 600;
    font-size: 16px;
    padding: 30px 0 16px;
  }

  .ant-modal-body {
    .ant-card-body {
      padding: 0;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";