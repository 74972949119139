@import 'src/themes/default/default';
@import '../widgets.less';

.half-circle-statistics {
  .widget-mixin();
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-width: 176px;
  min-height: 204px;
  max-width: 264px;

  &__chart {
    align-self: center;
  }

  &__description {
    flex-grow: 1;

    &__entry {
      display: flex;
      align-items: baseline;

      &__key {
        margin-right: 8px;
        color: @faint-dark;
      }

      &__list-marker {
        flex-shrink: 0;
        height: 8px;
        width: 8px;
        border-radius: 1px;
        margin-right: 8px;
      }
    }


  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";