@import "../../report-configuration.mixins";

.report-properties-settings {

  &__form {
    .form-align-items-mixin();
  }

  &__buttons-bar {
    .buttons-bar-mixin();
    margin-bottom: 24px;
  }

  &__section-title {
    .section-title-mixin();

    &::after {
      margin-left: 0;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";