@import "src/themes/default/default";
@import "../../shared/styles/mixins";

.form-modal {
  .ant-card-head-wrapper {
    .ant-card-head-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: @text-gray;
    }
  }
}

.form {
  .form-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;
  }

  .ant-form-item {
    flex-grow: 0;
    margin-bottom: 0;
    height: 100%;

    &.without-label {
      .ant-form-item-control {
        height: 100%;
        justify-content: flex-end;
      }
    }

    &.not-display {
      display: none;
    }
  }

  .ant-space {
    width: 100%;

    .ant-space-item {
      width: 100%;
      white-space: nowrap;

      .save-action-btn {
        .button-primary();
        width: 100%;
      }
    }
  }

  .simple-action-btn {
    width: 100%;
  }

  &.actions-auto-width {
    .ant-space {
      .ant-space-item {
        width: auto;
      }
    }
  }

  .administration-settings-control-ratios-rules {

    .field,
    .ant-form-item {
      flex-grow: 1;
    }
  }
}

.form-with-tabs {
  .ant-typography {
    color: @text-gray;
  }

  .ant-card-head {
    border: none;
  }

  .ant-card-body {
    padding: 0 30px 30px;
  }
}

.settings-user-settings-roles-and-organizations {
  background: @white;
  border: none;
  padding: 0;
  margin: 0 0 24px 0;

  .subsections {
    width: 153px;
    margin-top: 0;
    margin-left: 0;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";