@import "src/themes/default/default.less";
@import "../../shared/styles/mixins";

.directory-tabs {
  border: 1px solid @line-faint-light;
  border-radius: 5px;

  // ====== Tabs with dot =====
  .ant-tabs {
    line-height: 1rem;

    .ant-tabs-tab {
      margin: 8px 0 0;
      padding: 12px 23px 12px 24px;
    }

    .ant-tabs-tab-btn {
      font-size: 16px;
      color: @faint-dark;

      &:hover {
        color: @base-dark;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: @text-complement-light;
        font-weight: 500;

        &:hover {
          color: @text-complement-light;
        }
      }

      &:after {
        content: '';
        background-color: @text-complement-light;
        width: 4px;
        height: 4px;
        border-radius: 9999px;
        margin-left: 12px;
        transform: matrix(1, 0, 0, -1, 0, 0);
        animation: select-animate .2s linear;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-tabpane {
    padding: 24px;
  }

  .ant-tabs-nav-wrap {
    margin-left: 16px;

    .ant-tabs-nav-list {
      min-width: 27.813rem;
    }
  }


  .ant-descriptions {
    .ant-descriptions-view {
      border: none;

      tr:nth-child(odd) {
        background-color: @gray-100;
      }

      .ant-descriptions-row {
        border-bottom: none;
        height: 48px;

        .ant-descriptions-item-label {
          width: 200px;
          background-color: transparent;
          border: none;
          color: @faint-dark;
        }

        .ant-descriptions-item-content {
          color: @text-gray;
        }
      }
    }
  }
}

@keyframes select-animate {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";