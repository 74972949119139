@import "src/themes/default/default";

.breadcrumbs {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 0 3px;

  width: fit-content;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 0 1rem 5px;

  .breadcrumb-link {
    &_text-link {
      cursor: pointer;
    }

    &.ant-typography {
      position: relative;
      color: @text-accent-dark !important;
      font-weight: 600;


      &:hover:after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 1px;
        background-color: @text-accent-dark;
      }
    }

    &__divider {
      color: @text-accent-dark;
      align-self: flex-start;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";