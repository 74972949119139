
.files-cell {
  .ant-upload-list-item-card-actions {
    position: unset !important;
  }
}

.file-column {
  .icon-add-files {
    width: 18px;
    height: 18px;
    padding-bottom: 2px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";