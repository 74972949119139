.custom-select {
    &.ant-select {
        &-sm {
            min-height: unset;
            .ant-select-selector {
                min-height: unset !important;
            }

            .ant-select-selection-placeholder {
                line-height: 20px !important;
            }

            .ant-select-selection-item {
                margin-top: 0 !important;
                line-height: 24px;
            }
        }
    }

    .ant-select-selector {
        & > span {
            line-height: 28px;
        }
    }

    &.ant-select-multiple {
        .ant-select-selection-placeholder {
            top: 46%;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";