@import "src/themes/default/default";
@import "../../../shared/styles/mixins";

.tooltip-field {
  align-self: flex-start;
  color: @gray-icon;
  font-size: 16px;
  margin-top: 38px;

  svg {
    cursor: pointer;
  }

  &:hover {
    svg {
      fill: @link-complement-light;
    }
  }

  &__content {
    font-size: 12px;

    .ant-popover-inner {
      border-radius: 5px;
      .shadow();
    }

    .ant-popover-arrow {
      z-index: 2;
    }

    .ant-popover-inner-content {
      padding: 12px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";