@import '../../document-upload-template.mixins.less';
.configurator-template {
  &__buttons-bar {
    .section-title-mixin();
    margin-bottom: 24px;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &__title {
      .section-title-mixin();
      font-size: 14px;
      font-weight: 600;
      margin: 16px 0;
    }

    &__body {
      .form-flex-mixin();
      .ant-form-item{
        margin: 0;
      }
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";