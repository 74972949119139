@import "src/themes/default/default";

.ant-modal-body {
  .ant-card {
    border: none;
  }
}

.modal-close-icon {
  width: 14px;
  height: 14px;
  stroke: @gray-900;

  &.with-title {
    position: absolute;
    top: 36px;
    right: 32px;
  }
}

.modal-confirm .ant-modal-confirm-btns {
  display: flex;
  margin-top: 48px;
}

.modal-confirm .ant-btn {
  height: 36px;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";