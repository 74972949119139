.custom-card-title {
  display: flex;
  align-items: center;
  padding: 0 28px 0 0;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.custom-card-title{
  display: flex;
  align-items: center;
  padding: 0;

  .custom-title{
    margin-bottom: 0.5em;
    color: rgba(20, 20, 20, 0.85);
    font-weight: 400;
    font-size: 24px;
    line-height: 1.23;
    padding: 0;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";