@import "src/themes/default/default";

.monitoring-logs {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
}

.monitoring-log {
  display: flex;
  gap: 0 8px;
  align-items: center;

  .program-name {
    margin-right: auto;
  }

  .anticon {
    color: @link-complement-light;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: 5px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";