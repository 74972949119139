@import "src/themes/default/default";

.form {
  .ant-form-item-has-success {
    color: rgb(24, 128, 223);
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0;
    color: @text-gray;

    .ant-checkbox {
      margin-top: 2px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";