@import 'shared/styles/mixins';

.form-flex-mixin() {
  .form-flex {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-form-item-explain.ant-form-item-explain-error {
      margin-top: 6px;
    }

    .ant-form-item-label {
      & > label {
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
      vertical-align: unset !important;

      @media (max-width: 575px) {
        .ant-col {
            padding: 0px;
            flex: unset !important;
        }
      }

      .ant-input-number {
        width: 100%;
        &-input {
          height: 34px;
        }
      }
    }

    .form-flex-row {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      align-items: flex-end;

      .ant-form-item {
        flex-grow: 1;
        flex-basis: 0;
        flex-shrink: 0;
        min-width: 0;

        @media (max-width: 575px) {
          min-width: 100%;
        }
      }
    }
  }
}

.flex-fields {
  &__card-title {
    & >.ant-card-head {
      .card_head();
    }
  }

  &__delete-btn {
    .button-critic();
  }

  &__form-flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    max-width: 380px;
    justify-content: flex-start;

    .ant-form-item {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
      min-width: 0;

      @media (max-width: 575px) {
        min-width: 100%;
      }
    }
  }
}

.form-item__checkbox {
  margin-bottom: 0;
}
