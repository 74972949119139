@import "../../../../shared/styles/mixins";

.input__number {
  .input();
  box-shadow: none !important;

  .ant-input-number-input-wrap {
    width: 100%;
  }

  input {
    &::placeholder {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      color: @placeholder-gray;
      font-size: 14px;
      line-height: 18px;
    }
    background-color: transparent;
    border-color: transparent;

    &:hover, &:focus {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none;
    }
  }

  width: 100%;
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";