@import "src/themes/default/default";

.widget-layout {
  background-color: @gray-100;
  border-radius: 5px;
  border: 1px solid @line-faint-light;
  padding: 16px;
  display: flex;
  flex-direction: column;

  &.matrix {
    .widget-layout {
      &__up-bar {
        &__title {
          width: 192px;
        }
      }
    }
  }

  &__icon {
    margin-bottom: 16px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  &__body {
    display: flex;
    row-gap: 16px;

    &_with-wrap {
      flex-wrap: wrap;
    }

    &_justified {
      justify-content: center;
    }

    &_with-auto-overflow {
      overflow: auto;
    }

    // overflow: auto;

    &>div {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__up-bar {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
    }

    &__bar {
      height: 1px;
      flex-grow: 1;
      margin: 0 16px;
      background-color: @line-faint-light;
    }

    &__buttons {
      display: flex;

      &>a {
        &:last-child {
          margin-right: 0;
        }

        margin-right: 8px;
      }
    }
  }

  &__footer {
    display: flex;
    margin-top: 16px;
    flex-grow: 1;
    align-items: flex-end;

    &>a {
      &:last-child {
        margin-right: 0;
      }

      margin-right: 8px;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";