@import "../../../../shared/styles/mixins";

.editable-cell {
  &__value {
    width: 100%;
    min-height: 32px;
    &.hidden {
      display: none;
    }
  }

  &__popover {
    font-size: 12px;

    .ant-popover-inner {
      border-radius: 6px;
      .shadow();
    }

    .ant-popover-arrow {
      z-index: 2;
    }

    .ant-popover-inner-content {
      padding: 8px;
    }

    &.hidden {
      display: none;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";