@import "../../../../shared/styles/mixins";

.form-list__container:last-child {
  &.with-margin-bottom {
    margin-bottom: 16px;
  }
}


.form-list__container {
  width: max-content !important;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  justify-content: flex-start;
  grid-template-areas: "a b ." "c c c";
  column-gap: 16px;

  &_dragged {
    border: 1px solid #43A047;
    background-color: rgba(#43A047, .05);
    opacity: 0.9;
    border-radius: 6px;
    padding: 18px;
    margin: 20px 0px;
  }

  .ant-form-item-explain {
    min-height: 0;
  }

  &.with-margin-left {
    margin-left: 40px;
  }

  .form-list__items:first-child {
    margin-top: 0;
  }

  .items-row {
    max-width: 1375px;
  }

  .items-row-large {
    max-width: 1416.2px;
  }

  .form-list__items {
    grid-area: a;
    align-items: start !important;
    margin-bottom: 0 !important;
    margin-top: 16px !important;


    .collapse-action-btn {
      padding: 0;
      margin-top: 34px;

      svg {
        width: 22px;
        height: 22px;
        fill: @link-complement-light !important;
      }

      &:hover {

        svg {
          fill: @blue-600;
        }
      }
    }
  }


  .button-container {
    grid-area: b;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: left;

    .add-action-btn {
      margin-top: 47px;

      svg {
        width: 22px;
        height: 22px;
      }

      &:hover {
        path {
          fill: @blue-600 !important;
        }
      }
    }
  }

  .child {
    display: flex;
  }

  .contents-child {

    &.no-visible {
      display: none;
    }

    grid-area: c;
    display: flex;

    .line-vertical {
      border-left: 1.8px solid @link-complement-light;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      height: auto;
      margin-left: 11.5px;

      &.last-child {
        height: 65px;
      }
    }

    .margin-left {
      margin-left: 52px;
    }

    .report-table-contents-child {
      display: flex;
      align-items: flex-start;

      .line-horizontal {
        width: 34px;
        border-bottom: 1.8px solid @link-complement-light;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        margin-top: 64px;
        margin-right: 8px;
        height: 0;
      }
    }
  }


  .list-child:last-child {
    margin-bottom: 16px;
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";