@import 'src/themes/default/default';
@import 'src/shared/styles/mixins.less';

.section-title-mixin() {
  display: flex;
  font-size: 24px;
  font-weight: 400;
  padding: 0;
  line-height: 28px;
  color: @text-accent-dark;
  margin: 0 0 26px 0;

  &::after {
    content: '';
    display: inline-block;
    flex-grow: 1;
    height: 1px;
    background-color: @blue-100;
    margin: auto 0 auto 16px;
  }
}

.buttons-bar-mixin() {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  button.ant-btn>.anticon>svg {
    height: 18px;
    width: 18px;
  }
}

.form-align-items-mixin {
  .form-list__items {
    display: flex;
    align-items: baseline !important;
  }
}

.form-flex-mixin() {
  .ant-row.ant-form-item {
    @media (max-width: 575px) {
      .ant-col {
        flex: unset !important;
      }
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0 0 16px 0;

    &_grow {
      .ant-row.ant-form-item {
        flex-grow: 1;
      }
    }

    &_align-end {
      align-items: end;
    }

    .ant-checkbox-wrapper {
      margin: 0;

      &>span {
        white-space: normal;
      }
    }
  }
}

.form-list-mixin() {
  .scroll();
  overflow: auto;
  padding-bottom: 16px;
  margin-top: 16px;

  &__items {
    display: flex;
    column-gap: 16px;
    align-items: baseline;

    .ant-input-number {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &>.ant-row.ant-form-item {
      flex-shrink: 0;
      margin-bottom: 0 !important;
    }

    button {
      align-self: flex-end;
    }
  }
}