@import "src/shared/styles/mixins";

.modal-change-history {

  .ant-modal-close-x {
    margin: 14px 10px 0 0;

    .modal-close-icon {
      width: 11px;
      height: 11px;
    }
  }

  .ant-modal-body {
    padding: 14px 30px 30px 30px;

    .ant-card-head {
      padding: 0 24px 0 2px;
    }

    .ant-card-body {
      padding: 16px 2px 0 0;

      .ant-descriptions-view {
        .ant-descriptions-item {
          padding-bottom: 12px;

          .ant-descriptions-item-content {
            color: @black-0;
          }

          .ant-descriptions-item-label {
            color: @faint-dark;
          }
        }

        tr:last-child {
          .ant-descriptions-item {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";