@import 'src/themes/default/default';

.widget-title {
  font-weight: 600;
  font-size: 16px;
  color: @faint-dark;
  margin-bottom: 8px;
}

.widget-wrapper {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";