@import 'src/themes/default/default';

.crypto-pro-signing-input {
  display: flex;
  gap: 17px;
  color: @faint-dark;

  &__sign-icon {
    cursor: pointer;

    &:hover {
      color: @gray-600;
    }

    &_loading {
      svg {
        margin-left: 0px;
      }
    }
  }

  &__files {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    flex-direction: column;

    &__signing {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-start;
      width: 100%;
      margin-top: 8px;
    }

    &__empty {
      color: @black;
      border: 1px solid #E0E4F0;
      border-radius: 3px;
      height: 36px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      gap: 6px;
      width: 100%;

      &:hover {
        border: 1px solid @input-focused-light;
      }
    }

    &__file {
      width: 100%;
      padding: 6px 10px;
      border: 1px solid @line-faint-light;
      border-radius: 3px;
      color: @black;
      display: flex;
      align-items: center;
      flex-grow: 1;

      &:hover {
        border: 1px solid @input-focused-light;
      }

      &__name {
        overflow: hidden;
        flex-grow: 1;
        word-break: break-word;
      }

      &__icon {
        color: @faint-dark;
        margin-right: 4px;
        flex-shrink: 0;

        &-close {
          font-size: 12px;
          margin-left: 8px;
          font-weight: 600;
          color: #ED1C24;
          cursor: pointer;
        }
      }
    }
  }

  &__sign-component {
    color: @gray-500;

    &:hover {
      color: @faint-dark;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";