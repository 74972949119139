@import 'src/shared/styles/mixins';

.date-input {
  .input();

  min-width: unset;

  &::placeholder {
    color: @faint-dark;
  }

  .ant-picker-input>input::placeholder {
    color: @faint-dark;
  }

  .ant-picker-input>input:hover,
  .ant-picker-input>input:focus {
    border: none;
    box-shadow: none;
  }

  &.ant-picker-disabled {
    &:hover {
      border: 1px solid @line-faint-light;
    }
  }

  .ant-picker-clear {
    right: -2px;

    .clear-icon {
      fill: @faint-dark;
    }
  }
}

@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";