.modal-close-confirmation {
    .ant-modal-content {
        white-space: pre-wrap;
        .ant-modal-close-x {
            cursor: default;
        }
    }
    &__controls {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 8px;
        & > button {
            min-width: 160px;
            flex-grow: 1;
        }
    }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";