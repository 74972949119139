@import "src/themes/default/default";

.sign-list-uploader {
  display: flex;
  flex-direction: column;
  gap: 8px 0;

  .sign-uploader-wrapper {
    display: flex;
    align-items: center;
    gap: 0 8px;

    .download-icon {
      margin-top: 3px;
      fill: @gray-icon;
      cursor: pointer;
    }

    .ant-upload-disabled {
      .download-icon {
        cursor: not-allowed;
      }
    }
  }

  .sign {
    display: flex;
    height: 36px;
    padding: 8px 5px 8px 7px;
    align-items: center;
    gap: 0 4px;
    flex: 1 0 0;

    border-radius: 3px;
    border: 1px solid @line-faint-light;
    background: @white;

    .ant-typography {
      color: @faint-dark;
      line-height: 130%;
    }
  }

  .file-field-info {
    padding: 8px 5px 8px 7px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";