@import 'src/themes/default/default';
.report-action {
  display: flex;
  flex-direction: row;

  .table-action {
    border-radius: 5px 0 0 5px;
  }

  .ant-select {
    height: 100%;

    .ant-select-selector {
      position: relative;
      background-color: @complement-dark;
      border: none;
      border-radius: 0 5px 5px 0;
      height: 36px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      .ant-select-arrow {
        color: @base-light;
      }
    }

    .ant-select-selection-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      color: @base-light;
    }
  }

}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";