@import 'src/themes/default/default';

.upload-dropzone-file {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    gap: 10px;
    padding: 0px 6px;
  }

  &__right {
    &__trash {
      color: @gray-400;
      cursor: pointer;

      &:hover {
        color: @gray-500;
      }
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";