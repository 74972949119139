@import '../../report-configuration.mixins.less';
@import '../../../../shared/styles/mixins.less';

.report-comparison-settings {

  .scroll {
    .scroll();
    overflow: auto;

    .body-container {
      display: inline-block;
      min-width: 100%;

      &.no-visible {
        display: none;
      }

      .button-container {
        display: flex;
        gap: 16px;
        margin-bottom: 24px;
      }

      .fields-container {
        display: grid;
        grid-template-columns: repeat(4, min-content);
        justify-content: flex-start;
        grid-template-areas: "a b c ." "d d d d" "e e e e";
        column-gap: 16px;

        .section-list {
          grid-area: a;
          display: flex;
          gap: 16px;
          margin-bottom: 20px;

          &__add-key-button {
            margin-bottom: 20px;
            margin-left: -16px;
          }
          
          .ant-row.ant-form-item {
            margin-bottom: 0px;
          }
        }

        .button-add-field:first-child {
          grid-area: b;
        }

        .button-add-field:last-child {
          grid-area: c;
        }

        .report-comparison-form-list:last-child {
          grid-area: e;
          margin-bottom: 32px;
        }

      }

    }
  }

  .line {
    .section-title-mixin();

    &::after {
      margin-left: 0;
    }
  }

  .report-comparison-form-list {
    grid-area: d;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .form-list__items {
      display: flex;
      column-gap: 8px;
      align-items: flex-start !important;
      margin-bottom: 0 !important;

      .ant-input-number {
        width: 100%;
      }
    }

    &__empty-fields {
      color: @gray-600;
    }
  }

  &__buttons-bar {
    .buttons-bar-mixin();
    margin-bottom: 24px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";