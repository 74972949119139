@import "src/themes/default/default";

.signature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;

  .ant-form-item-control-input-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &-info {
    display: flex;
    align-items: center;

    &-spin {
      margin-left: 4px;
      margin-top: -4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &__icon {
      margin-left: 4px;
      cursor: pointer;
      fill: @gray-icon;
    }
  }

  &-actions {
    display: flex;
    gap: 16px;
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";