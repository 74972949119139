@import 'src/themes/default/default';

.ais-register-dashboard {
  &__body {
    display: flex;
    gap: 21px;

    .d-flex.gap-1 {
      >div:first-child {
        width: 80px;
      }

      >div:not(:first-child) {
        width: 260px;
      }

      >div:last-child {
        width: 123px;
      }
    }

    &__svg {
      display: flex;

      &__file-folder-svg {
        width: 171px;
        height: 117px;
        margin: auto;
      }
    }

  }

  &__stats {
    display: flex;
    flex-direction: column;

    &__title {
      color: @gray-600;
      font-size: 16px;
    }

    &__value {
      font-size: 40px;
      white-space: nowrap;
      font-weight: 600;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;

    .ant-btn-sm {
      width: 120px !important;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";