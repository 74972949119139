@import "../../../../../report-configuration.mixins";

.icon-format-modal-content {
  .conditions-modal-mixin();

  &__form__row__top {
    .icon-options {
      width: 16px;
      vertical-align: middle;
    }
  }
}
@hack: true; @import "/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default";